#section00 {
  background-attachment: fixed;
  /*max-height: 740px;*/
  min-height: 980px;
  position: relative;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.video_bg {
  width: 110%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  min-height:740px
}
.video_bg iframe {
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video_bg .videoPlayer {
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video_bg::after {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
