{
    text-decoration: none
;
}

@font-face {
    font-family: 'TT-Trailers';
    src: url('/public/fonts/TypeType-TT-Trailers-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fobble';
    src: url('/public/fonts/Fobble_regular-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kvant-Menco-Black';
    src: url('/public/fonts/Kvant-Menco-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kvant-Menco-Medium';
    src: url('/public/fonts/Kvant-Menco-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {height: 100%;}
body {
    height: 100%;
    overflow-x: hidden;
    padding-right: 0 !important;
    text-align: center;
    background-position: top center;
    background-repeat: repeat-x;
    color: #fff;
    font-size: 14px;
    font-family: 'TT-Trailers';
    transition: 0.3s;
    background-attachment: fixed;
    min-width: 1200px;
    user-select: none;
}

.box_btn01 {
    border: 0px !important;
    background: none
}

.box_btn02 {
    border: 0px !important;
    background: none
}

.box_btn03 {
    border: 0px !important;
    background: none
}

button[tabindex], span[role="button"][tabindex] {
    outline: none; /* 포커스 시 아웃라인 제거 */
}

.content {
    transition: transform 0.5s ease; /* 애니메이션 효과 */
    position: relative;
}

.page-transition {
    position: fixed;
    top: 100%; /* 화면 아래에서 시작 */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* 배경색 설정 */
    transform: translateY(-100%); /* 초기 위치: 화면 아래 */
    transition: transform 0.5s ease; /* 애니메이션 효과 */
    z-index: 10; /* 다른 요소 위에 표시 */
}

.page-transition.active {
    transform: translateY(0); /* 화면으로 올라옴 */
}

.m_menus {
    position: absolute;
    bottom: 80px;
    border: 0px;
    background: none;
    left: 50%;
    margin-left: -30px;
    z-index: 2
}

.right_cha {
    display: none
}

.left_cha {
    display: none
}

.wallet button {
    background: none;
    border: 0px
}

.small_img2 {
    width: 98%;
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 3
}


/*main*/

.p_area {
    height: 580px;
    position: relative;
}

.modal-body .bg {
    width: 100%;
    height: 100%;
    max-width: 970px;
    height: 450px;
    border-radius: 30px 30px 0px 0px;
    position: absolute;
    z-index: -1;
    bottom: 0;

}

.modal-body .b09 img {
    position: absolute;
    bottom: 0;
    z-index: 10;
}

.cristal {
    text-align: center;
    position: relative;
    bottom: -30px;
    left: 50%;
    margin-left: -230px;
    max-width: 440px;
    max-height: 440px;
}

.cristal img {
    width: 100%;
    height: 440px;
    object-fit: cover;
    border: 8px solid #fff;
    border-radius: 30px;
}

.cha_ico {
    /*position: absolute; 20241202 khs absolute -> relative*/
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: -webkit-center;
}

.p_area h1 {
    font-family: 'TT-Trailers';
    font-size: 80px;
    text-shadow: -4px 3px 1px #00142d;
    -webkit-text-stroke: 1px #00142d;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    padding-top: 27px;
}


/*980 시작*/
@media (max-width: 980px) {
    .p_area {
        height: 480px;
        position: relative
    }

    .modal-body .bg {
        width: 100%;
        height: 100%;
        max-width: 970px;
        height: 380px;
        border-radius: 30px 30px 0px 0px;
        position: absolute;
        z-index: -1;
        bottom: 0;
    }

    .cristal {
        width: 100%;
        left: 0%;
        margin-left: 15%;
    }

    .cristal img {
        width: 40%;
        margin-left: 15%;
    }

    .cha_ico img {
        width: 80%
    }
}

/*980 끝*/
/* 500 시작*/
@media (max-width: 500px) {
    .p_area {
        height: 220px;
        position: relative
    }

    .modal-body .bg {
        width: 100%;
        height: 100%;
        max-width: 970px;
        height: 170px;
        border-radius: 30px 30px 0px 0px;
        position: absolute;
        z-index: -1;
        bottom: 0;
    }

    .cristal {
        width: 100%;
        left: 0%;
        margin-left: 15%;
    }

    .cristal img {
        width: 40%;
        margin-left: 15%;
    }

    .cha_ico img {
        width: 90%
    }

    .modal-close-btn {
        width: 28px;
        height: 28px;
        background-color: #000;
        border: none;
        z-index: 9;
        position: absolute;
        border-radius: 0%;
        right: 10px;
        top: 70px;
    }

    .modal-close-btn img {
        width: 28px;
    }

    .modal-body .b01 {
        background: url(/public/image/chbg01.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b02 {
        background: url(/public/image/chbg02.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b03 {
        background: url(/public/image/chbg03.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b04 {
        background: url(/public/image/chbg04.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b05 {
        background: url(/public/image/chbg05.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b06 {
        background: url(/public/image/chbg06.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b07 {
        background: url(/public/image/chbg07.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b08 {
        background: url(/public/image/chbg08.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b09 {
        background: url(/public/image/chbg09.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b10 {
        background: url(/public/image/chbg10.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b11 {
        background: url(/public/image/chbg11.png) bottom no-repeat;
        background-size: 100%;
    }

    .modal-body .b12 {
        background: url(/public/image/chbg12.png) bottom no-repeat;
        background-size: 100%;
    }

    .p_area h1 {
        font-family: 'TT-Trailers';
        font-size: 40px;
        text-shadow: -2px 2px 1px #00142d;
        -webkit-text-stroke: 1px #00142d;
        margin-top: 12px;
    }
}

/*500 끝*/
/* custom */
#odometer1 {
    font-size: 40px;
    min-width: 290px;
}

.odometer-value, .odometer-formatting-mark {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(#fffd00, #fa7900);
    background-size: 100% 100%;
    position: relative;
    color: #ffffff;
    font-family: noto-sans-black;
    white-space: nowrap;
}

.mgnb {
    display: none
}

.h1_txt {
    font-size: 40px;
    background: linear-gradient(to right top, #fff, #eb781d);
    color: transparent;
    -webkit-background-clip: text;
    font-family: 'SBAggroB';
    font-weight: 900;
}

.logo .h1_txt {
    font-size: 27px;
    line-height: 70px;
}
.logo_btn img{
    width: 100%;
    max-width: 180px;
}
.nonebg {
    background: #000 !important
}

.parsley-errors-list {
    position: absolute;
}

.none_bg {
    background: none !important
}

.green_bg {
    background: #CEF8D2 !important
}

.yell_bg {
    background: #FFEBB8 !important
}

.red_bg {
    background: #FFD3D3 !important
}

.blue_bg {
    background: #B5E4FF !important
}

.w-100 {
    width: 100%
}

.w-50 {
    width: 50%
}

.w-30 {
    width: 30%
}

.w-25 {
    width: 25%
}

.w-20 {
    width: 20%
}

.w-10 {
    width: 10%
}

.mt100 {
    margin-top: 100px
}

.parsley-errors-list.filled {
    margin-top: 75px;
    margin-left: -35px;
    list-style: none;
    font-size: 12px;
    font-weight: bolder;
    color: #ffe300;
    -webkit-transition: opacity 2s;
    transition: opacity 2s;
}

.popup_window {
    position: fixed;
    background-color: #fff;
    color: #000000;
    padding: 0;
    display: none;
    z-index: 999;
}

.wrapper_loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1976;
}

#store .slide_list > li img {
    width: 100%;
    border: 3px solid #000;
    border-radius: 30px;
}

.wrapper_loading_img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
    width: 120px;
    height: 120px;
}

.level-icon {
    max-width: 28px;
    max-height: 28px;
}

.hidden {
    display: none !important
}

.cursor-pointer, .player-balance {
    cursor: pointer;
}

.label {
    border: 1px solid #000
}

.label-warning {
    background-color: #cbb956
}

.label-warning[href]:focus, .label-warning[href]:hover {
    background-color: #b6a338
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em
}

.label:empty {
    display: none
}

.btn .label {
    position: relative;
    top: -1px
}

a.label:focus, a.label:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.mt30 {
    margin-top: 30px
}

.label-default {
    background-color: #777
}

.label-default[href]:focus, .label-default[href]:hover {
    background-color: #5e5e5e
}

.label-primary {
    background-color: #3097d1
}

.label-primary[href]:focus, .label-primary[href]:hover {
    background-color: #2579a9
}

.label-success {
    background-color: #2ab27b
}

.label-success[href]:focus, .label-success[href]:hover {
    background-color: #20895e
}

.label-info {
    background-color: #8eb4cb
}

.label-info[href]:focus, .label-info[href]:hover {
    background-color: #6b9dbb
}

.label-warning {
    background-color: #cbb956
}

.label-warning[href]:focus, .label-warning[href]:hover {
    background-color: #b6a338
}

.label-danger {
    background-color: #bf5329
}

.label-danger[href]:focus, .label-danger[href]:hover {
    background-color: #954120
}

.custom-label {
    padding: 0 0.5rem 0 0.5rem;
    font-size: 13px;
}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none
}

li {
    list-style: none
}

.tab {
    max-width: 1430px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0px;
    overflow: hidden;
    cursor: pointer;
    font-size: 18pt
}

.tab img {
    height: 120%;
    float: right;
}

.tab .c_tab {
    width: 50%;
    float: left;
    list-style: none;
    padding: 10px 0px;
    /*background:url("/public/image/img/bg/merch_btn.png");*/
    font-weight: bold;
    font-family: 'SBAggroB';
    height: 150px;
    line-height: 140px;
    border-radius: 10px 0px 0px 10px;
    text-shadow: 1px 1px 3px #000;
    font-size: 25pt;
    filter: grayscale(100%);
    transition: 0.3s;
}

.tab .c_tab:hover {
    width: 50%;
    float: left;
    list-style: none;
    padding: 10px 0px;
    /*background:url("/public/image/img/bg/merch_btn.png");*/
    font-weight: bold;
    font-family: 'SBAggroB';
    height: 150px;
    line-height: 140px;
    border-radius: 10px 0px 0px 10px;
    text-shadow: 1px 1px 3px #000;
    font-size: 25pt;
    filter: grayscale(0%);
    transition: 0.6s;

}


.tab .achive {
    filter: grayscale(0%) !important;
}

.popular-section {
    clear: both
}

.customer-panel i {
    color: #333;
    margin: 0px 5px
}

.customer-panel strong {
    color: #333;
    padding-right: 5px
}

.customer-panel span {
    color: #333;
    background: #ff6600;
    padding: 0px 7px;
    border-radius: 80px;
    font-size: 9pt;
    margin-left: 5px
}

.mo_edit {
    border: 1px solid #eee;
    padding: 10px;
    margin-bottom: 20px
}

.mobile .bs-ul li {
    width: 100%;
    text-align: left
}

.merge-section {
    margin: 60px 0 0;
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
}

.cate h2 {
    text-align: left;
    padding: 40px 30px;
    font-size: 16pt;
    font-family: 'TT-Trailers';
    font-weight: 600
}

.cate li {
    float: left;
    padding: 40px 30px;
    font-family: 'TT-Trailers';
    cursor: pointer
}

.cate a {
    color: #333
}

.cate li:hover {
    font-weight: 600;
    color: #477dfd
}

.cate .achive {
    font-weight: 600;
    color: #477dfd
}

.merch-section {
    max-width: 1340px;
    width: 100%;
    margin: 0 auto
}

.g-name h3 {
    font-size: 17px;
    font-weight: 600;
    font-family: 'TT-Trailers';
}

.g-name h4 {
    font-size: 24px;
    font-weight: 400;
    font-family: 'TT-Trailers';
    color: #477dfd
}

.g-name h4 s {
    font-size: 18px;
    font-weight: 400;
    font-family: 'TT-Trailers';
    color: #777
}

.cart {
    border: 1px solid #183153;
    background: #fff;
    border-radius: 5px;
    width: 22px;
    padding: 3px;
    margin-left: 10px
}

.cart img {
    width: 100%
}

.search {
    float: right;
    padding: 30px 30px;
    display: flex
}

.search input {
    border: 1px solid #ddd;
    height: 40px;
    border-radius: 30px 0px 0px 30px;
    padding-left: 30px
}

.search button {
    border: 1px solid #477dfd;
    height: 40px;
    padding: 0px 20px;
    border-radius: 0px 30px 30px 0px;
    background: #477dfd;
    color: #fff;
    font-family: 'TT-Trailers';
}

.g-panel input {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 36px;
    left: 36px;
    margin: 0;
}

.merge-section h3 {
    color: #333;
    text-align: left;
    font-size: 12pt;
    padding-bottom: 30px;
    font-family: 'TT-Trailers';
    font-weight: 400
}

.merge_code {
    height: 74px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 830px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.merge_code input {
    width: 100%;
    border: 2px solid #000;
    border-radius: 20px;
    box-shadow: 5px 5px 0 #000;
    height: 60px;
    box-sizing: border-box;
    padding-left: 150px;
    font-size: 20px;
}

.merge_code button {
    border-radius: 20px;
    box-shadow: 5px 5px 1px #000;
    line-height: 30px;

}

.grey {
    background: #A9FF99;
    -webkit-text-stroke: 1px #00142D;
    color: #fff;
    border: 0px;
    padding: 10px 30px;
    border-radius: 10px;
    font-family: 'Kvant-Menco-Black';
    margin: 0px 10px;
    font-size: 26px;
    width: 312px;
    height: 68px;
    letter-spacing: 0px
}

.blue {
    background: #A9FF99;
    -webkit-text-stroke: 1px #00142D;
    color: #fff;
    border: 0px;
    padding: 10px 30px;
    border-radius: 10px;
    font-family: 'Kvant-Menco-Black';
    margin: 0px 10px;
    font-size: 26px;
    width: 312px;
    height: 68px;
    letter-spacing: 0px
}

.merge-panel a {
    position: relative;
    width: 100%;
    max-width: 236px;
    border-radius: 15px;
    border: 2px solid #00142D;
    background-color: white;
}


/*.merge-panel a:nth-child(6) {*/
/*    margin: 0 0 25px 0;*/
/*}*/

.merge-btn .svg-right {
    position: absolute;
    z-index: 3;
    right: -10px;
    top: -10px
}

.small_img3 img {
    z-index: 3;
}

.small_img3 img:first-of-type {
    position: absolute;
    left: 10px;
    top: -20px;
}

.small_img3 img:last-of-type {
    position: absolute;
    right: 10px;
    top: -13px;
}

.merge_btn {
    background: #477DFD;
    -webkit-text-stroke: 1.5px #00142D;
    color: #fff;
    border: 2px solid black;
    border-radius: 20px;
    font-family: 'Kvant-Menco-Black';
    font-size: 26px;
    letter-spacing: 1%;
    font-weight: 900;
}


.wallbody {
    padding: 36px 36px 42px
}

.loginPage{
    /*padding: 80px 60px;*/
    padding: 60px 60px;
}
.mergeModal{
    padding: 60px 25px;
}

  .loginPage li:first-of-type {
    margin-bottom: 20px;
}

.bg_white, .bg_white li {
    background: url('/public/image/product_boxbg.png');
    background-size: 100% 100%;
}

.wall_ment {
    color: #00142D;
    font-family: Kvant-Menco-Black;
    font-size: 20px;
    max-width: 262px;
    line-height: 22px;
    margin: 2px auto 20px;
    text-align: left;
}

.swiper-location {
    width: 100%
}


/*원페이지*/

header {
    width: 100%;
    position: fixed;
    top: 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    z-index: 13;
}

#land {
    width: 100%;
    height: 100%;
    /* background-image: url("/public/image/bg_01.png"); */
    background-image: url("/public/image/notice_bg.png");
    background-attachment: fixed;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
    background-size: 100% 100%;
}
#prologue {
    width: 100%;
    /*min-height: 740px;*/
    height: 100%;
    background-size: 100% 100%;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
}

#section011 {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
    background-size: 100% 100%;
}

#shop {
    width: 100%;
    height: auto;
    background-image: url("/public/image/notice_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative
}

#section03 {
    width: 100%;
    height: auto;
    background-image: url("/public/image/store_bg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
}
#section04{
    height: 100%;
}

#project {
    width: 100%;
    /*min-height: 980px;*/
    /*height: auto;*/
    height: 100vh;
    background-size: 100% 100%;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
}

#section05 {
    width: 100%;
    height: 182px;
    /*min-height:182px;*/
    background: #00142D;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
    border-top: 2px solid #000
}


#shop, #section03 {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /*height: 980px;*/
    height: 100%;
    position: relative;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    width: 100%;
}


.video_bg {
    overflow: auto;
    position: relative;
    width: 100%;
    padding-bottom: 54%;
    height: auto;
}

.video_bg iframe {
    position: absolute;
    top: -60px;
    bottom: -60px;
    left: 0;
    width: 100%;
    height: calc(100% + 0px);
    border: none;
    pointer-events: none;

}

.video_bg .videoPlayer {
    position: absolute;
    top: -60px;
    bottom: -60px;
    left: 0;
    width: 100%;
    height: calc(100% + 0px);
    border: none;
    pointer-events: none;

}

.video_bg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.gnb {
    padding-left: 9px !important;
}

.gnb ul li a.active,
.m_login ul li a.active {
    color: #007bff; /* 붉은 색으로 변경 */
}

.footer {
    width: 100%;
    margin: 0 auto;
    font-family: 'Kvant-Menco-Black';
}

.logo_btn {
    float: left;
    width: 30%;
    max-width: 300px;
}

.logo_btn img {
    margin-top: 70px;
}

.copy {
    width: 70%;
    text-align: left;
    float: left;
    padding-top: 44px;
    color: #C6C7CC;
    font-family: 'Kvant-Menco-Black';
}
.copy a{
    color: #DAEAFF;
}
.copy a:link {
    color: #DAEAFF;
    font-size: 16px;
    padding-bottom: 10px
}

.copy a:hover {
    color: #DAEAFF;
    font-size: 16px;
    padding-bottom: 10px
}

/*intro*/
#apart.hidden {
    display: none;
}

/* .bg_01 {
background-image: url('/public/image/bg_01.png');
} */

.bg_02 {
    background-image: url('/public/image/bg_02.png');
}

.bg_03 {
    background-image: url('/public/image/bg_03.png');
}

/*land*/
.modal-close-btn {
    opacity: 0;
}

.areaModal {
    display: block;
    visibility: visible;
    position: relative;
    z-index: 9999
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* 검은색 배경, 70% 투명 */
    z-index: 9980; /* 팝업보다 낮은 z-index 설정 */
}

.slide {
    display: none; /* 기본적으로 모든 슬라이드는 숨김 */
}

/* active 클래스가 있는 슬라이드만 보이도록 */
.slide.active {
    display: block; /* 활성화된 슬라이드는 보이도록 설정 */
}

.header-modal-overlay {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* 검은색 배경, 70% 투명 */
    z-index: 9980; /* 팝업보다 낮은 z-index 설정 */
}


#land {
    position: relative;
}

#land .v_btn {
    margin: 0 auto;
    border: none;
    background: none;
    margin-top: 30px;
    width: 298px;
    height: 70px;
    background-image: url("/public/image/blue_btn.png");
    background-repeat: no-repeat;
    background-size: cover;
}

/* 슬라이더 컨테이너 */
.l-slider {
    padding-top: 80px;
}

.l-slider-container {
    position: relative;
    width: 100%;
    max-width: 1340px; /* 최대 너비 설정 */
    margin: auto;
    overflow: hidden;
}

/* 슬라이드 wrapper */
.l-slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out; /* 슬라이드 전환 애니메이션 */
    max-width: 1140px;
    margin: 0 auto;
    width: 300%;
}

.l-slide.active {
    opacity: 1; /* active 클래스가 있을 때 보이도록 설정 */
}

/* 개별 슬라이드 */
.l-slide {

    min-width: 100%; /* 각 슬라이드는 화면 너비 만큼 차지 */
    height: 100%; /* 슬라이드의 높이는 자동으로 이미지에 맞춰짐 */
    padding-top: 80px;
    opacity: 0;
    display: block;
    transition: opacity 0.5s ease-in-out; /* opacity 애니메이션 */
}

.l-slide video {
    width: 100%; /* 동영상 너비 100% */
    height: 590px; /* 동영상 높이 100% */
    object-fit: cover; /* 동영상 비율 유지하며 컨테이너를 완전히 채우도록 설정 */
    border: none; /* 테두리 제거 */
    border-radius: 20px; /* 테두리 둥글게 */
    overflow: hidden;
}


/* 슬라이드 안의 이미지 */
.l-slide img {
    width: 1000px;
    height: auto;
    display: block;
    max-height: 710px;
}

/* 네비게이션 버튼 스타일 */
.l-prev, .l-next {
    position: absolute;
    top: 50%;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10;
    width: 72px;
    height: 94px;
    background-repeat: no-repeat;
    background-size: cover;
}

.l-prev {
    left: 10px;
    background-image: url("/public/image/navi_before.png");
}

.l-next {
    right: 10px;
    background-image: url("/public/image/navi_next.png");
}

/* 반응형: 작은 화면에서 슬라이드 너비 및 높이 조정 */
@media (max-width: 768px) {
    .slide img {
        height: auto;
    }
}

@media (max-width: 480px) {
    .slider-container {
        max-width: 100%;
    }

    button {
        padding: 10px;
        font-size: 16px;
    }
}

/*store*/
.notice_view {
    background: url("/public/image/notice_bg.png");
    background-attachment: fixed;
}

.left_cha {
    position: absolute;
    top: 152px;
    left: 50%;
    z-index: 9;
    margin-left: 499px;
}

.left_cha2 {
    position: absolute;
    left: 50%;
    top: 598px;
    z-index: 9;
    margin-left: 499px;
}

.right_cha {
    position: absolute;
    top: 598px;
    z-index: 9;
    margin-left: -117px;
}

.left_cha img {
    width: 150px
}

.left_cha2 img {
    width: 200px
}

.right_cha img {
    width: 200px
}

#shop .notice_area {
    width: 1140px;
    height: 620px;
    margin: 0 auto;
    padding-bottom: 10px
}

#shop article {
    min-height: 900px;
    /*padding: 180px 0px*/
    padding: 130px 0px
}

#shop article figure {
    margin: 0px !important
}

#shop article button {
    border: 0px;
    margin: 5px;
    background: none
}

#shop article .button_box2 {
    display: flex;
    justify-content: center;
    padding-top: 12px;
}

#shop article .button_box2 button {
    display: flex;
    justify-content: center;
}

#shop .swiper-wrapper {
    /*max-height: 710px;*/
    width: 100%;
}

#shop .d_box2 .image img {
    height: auto;
    max-height: 350px;
    width: 100%;
}

#shop .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: -38px;
}

#shop .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 16px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 16px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #fff);
    border: 1.5px solid #00142D;
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    box-shadow: 2px 2px 1px #00142D;
}

#shop .swiper-pagination-bullet-active {
    /*opacity: var(--swiper-pagination-bullet-opacity, 1);*/
    /*background: var(--swiper-pagination-color, var(--swiper-theme-color));*/
    background-color: #477DFD;
}

/* 화살표 */
#shop .right_btn .btn {
    display: block;
    width: 58px;
    height: 58px;
    margin: 10px 0px;
    background: url('/public/image/arrow.png') center center no-repeat;
    background-size: cover;
    text-indent: -999em;
    cursor: pointer;
    transform: rotateY(0deg);
}

#shop .right_btn .btn:focus {
    outline: none !important;
}

#shop .right_btn .swiper-button-next {
    transform: rotateY(180deg);
}

#shop .right_btn {
    position: absolute;
    right: 13%;
    z-index: 11;
    margin-left: -0px;
    top: 50%;
    text-indent: -9999px;
    width: 58px;
    transform: translateY(-50%);
}

.swiper-slide-active .swiper-slide-content {
    cursor: pointer;
    margin-top: -160px;
    opacity: 1;
    transform: scale(.98);
}

.swiper-slide-active .swiper-slide-content img {
    border: 3px solid #000;
    border-radius: 30px;
}

.swiper-slide-active .swiper-slide-content .h1_text {
    background: #fff;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: 'Kvant-Menco-Black';
    color: #00142D;
    font-size: 31.4px;
    position: absolute;
    text-align: center;
    border: 1px solid #00142D;
    box-shadow: 0px 3px 1px #00142D;
    cursor: pointer;
    z-index: 1;
    margin-top: -541px;
    white-space: nowrap;
    overflow: inherit;
}

.swiper-slide-active .swiper-slide-content .h2_text {
    -webkit-text-stroke: 1px #00142d;
    word-wrap: break-word;
    background: #ffa3ff;
    border: 1.5px solid #00142d;
    border-radius: 20px;
    bottom: 300px;
    color: #fff;
    font-family: Kvant-Menco-Black;
    font-size: 38px;
    left: 50%;
    line-height: 43px;
    margin-left: -200px;
    padding: 50px;
    position: absolute;
    white-space: normal;
    width: 300px;
    word-break: keep-all;
}

.swiper-slide-active .swiper-slide-content .h3_text {
    -webkit-text-stroke: 1px #fff;
    color: #00142D;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: 'Kvant-Menco-Black';
    color: #00142D;
    font-size: 31.4px;
    position: absolute;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    margin-bottom: -445px;
    white-space: nowrap;
    overflow: inherit;
}

.swiper-slide-active .swiper-slide-content .h4_text {
    word-wrap: break-word;
    background: #a9ff99;
    border: 1.5px solid #00142d;
    border-radius: 20px;
    bottom: 330px;
    color: #00142d;
    display: none;
    font-family: Kvant-Menco-Black;
    font-size: 38px;
    left: 50%;
    line-height: 43px;
    margin-left: -200px;
    padding: 50px;
    position: absolute;
    white-space: normal;
    width: 300px;
    word-break: keep-all;
}

.swiper-slide-active .swiper-slide-content .h4_text img {
    border: 0;
    border-radius: 0;
    color: #00142d;
    width: 32px !important;
}

.swiper-slide-active .swiper-slide-content:hover .h1_text {
    background: #00142D;
    color: #fff;
    border: 1px solid #00142D;
    box-shadow: 1px 3px 1px 0 #fff, 2px 5px 0 0 #000;
}

.swiper-slide-active .swiper-slide-content:hover .h2_text {
    display: none
}

.swiper-slide-active .swiper-slide-content:hover .h3_text {
    display: none
}

.swiper-slide-active .swiper-slide-content:hover .h4_text {
    display: block
}

#project .background-img {
    z-index: 0 !important;
}

/*600 시작*/
@media (max-width: 667px) {
    .X-Social,.X-Social-eventModal{
        display: none;
    }
    .line{
        margin: 5px 0 !important;
        font-size: 15px;
    }

    #land{
        background-size: contain !important;
    }
    #store h1 {
        -webkit-text-stroke: 1px #00142d;
        font-family: TT-Trailers;
        font-size: 50px;
        text-shadow: -4px 4px 1px #000;
        margin-top: 267px;
        margin-bottom: 51px;
    }

    .price_container{
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        box-sizing: border-box;
    }

    #store .slider .inner {
        padding: 0px 0 0;
    }

    #section03 .swiper-slide img {
        border: 2px solid #00142d;
        border-radius: 30px;
        width: 71%;
        margin-top: 32px;
    }

    .swiper-slide-active .swiper-slide-content .h1_text {
        background: #fff;
        border: 1px solid #00142d;
        border-radius: 50px;
        box-shadow: 0 3px 1px #00142d;
        color: #00142d;
        cursor: pointer;
        font-family: Kvant-Menco-Black;
        font-size: 22.4px;
        margin-top: -307px;
        overflow: inherit;
        padding: 10px 20px;
        position: absolute;
        text-align: center;
        white-space: nowrap;
        z-index: 1;
    }

    .swiper-slide-active .swiper-slide-content .h2_text {
        -webkit-text-stroke: 1px #00142d;
        word-wrap: break-word;
        background: #ffa3ff;
        border: 1.5px solid #00142d;
        border-radius: 20px;
        bottom: 250px;
        color: #fff;
        font-family: Kvant-Menco-Black;
        font-size: 23px;
        left: 50%;
        line-height: 43px;
        margin-left: -105px;
        padding: 36px 10px;
        position: absolute;
        white-space: normal;
        width: 188px;
        word-break: keep-all;
    }

    .swiper-slide-active .swiper-slide-content .h3_text {
        -webkit-text-stroke: 1px #fff;
        border-radius: 50px;
        color: #00142d;
        cursor: pointer;
        font-family: Kvant-Menco-Black;
        font-size: 22.4px;
        margin-bottom: -288px;
        overflow: inherit;
        padding: 10px 20px;
        position: absolute;
        text-align: center;
        white-space: nowrap;
        z-index: 1;
    }

    .swiper-slide-active .swiper-slide-content .h4_text {
        word-wrap: break-word;
        background: #a9ff99;
        border: 1.5px solid #00142d;
        border-radius: 20px;
        bottom: 220px;
        color: #00142d;
        display: none;
        font-family: Kvant-Menco-Black;
        font-size: 22px;
        left: 79%;
        line-height: 25px;
        margin-left: -200px;
        padding: 50px;
        position: absolute;
        white-space: normal;
        width: 84px;
        word-break: keep-all;
    }

    #section00 {
        background-attachment: fixed;
        /*max-height: 740px;*/
        min-height: 0px;
        position: relative;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
        width: 100%;
        height: 100%;
    }

    .btn_list {
        display: flex;
        justify-content: center;
    }

    #shop .swiper {
        max-height: auto;
    }

    /*#shop article {*/
    /*    padding-top: 200px*/
    /*}*/

    #shop .swiper-wrapper {
        max-height: 710px;
        width: -webkit-fill-available;
    }

    #store .slide_list > li.swiper-slide-active .h1_text {
        left: 20%;
    }

    .left_cha {
        display: none
    }

    .right_cha {
        display: none
    }

    #shop .swiper-slide .d_box .detail_box h1 {
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        font-family: 'TT-Trailers';
        -webkit-text-stroke: 1px #00142D;
        text-shadow: -3px 3px 1px #00142D;
    }

    #shop .swiper-slide .d_box .detail_box h2 {
        color: #477DFD;
        font-family: 'Kvant-Menco-Black';
        font-size: 16px;
        line-height: 16px;
    }

    #shop .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: normal !important;
        background: #D2F4FF;
        border: 2px solid #00142D;
        border-radius: 30px;
        padding: 20px;
        width: 100% !important;
    }

    #shop .swiper-slide .d_box .detail_box h3 {
        font-family: 'Kvant-Menco-Black';
        font-size: 11px;
        color: #00142D;
        margin-bottom: 10px;
    }

    #shop .swiper-slide .d_box .detail_box .spci {
        font-family: 'Kvant-Menco-Black';
        font-weight: 900;
        background-color: #E9F7FB;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 8px;
        position: relative;
        height: 110px;
    }

    #shop .swiper-slide .d_box .detail_box .spci p {
        position: absolute;
        left: 14.5px;
        top: 47px;
        line-height: 1em;
        font-size: 10px;
        margin-bottom: 0px;
    }

    #shop .swiper-slide .d_box .detail_box .spci span {
        font-size: 13px;
        line-height: 11px;
        display: block;

    }

    .button_box {
        display: flex;
        clear: both
    }

    .modal-body {
        max-width: 100%;
        padding: 0;
        width: 100%;
        margin-top: 55%;
    }

    .slide-navigation {
        display: flex;
        justify-content: space-between;
        position: absolute;

        transform: translateY(-50%);
        width: 100%;
    }

    .next-slide {
        position: absolute;
        right: 10px;
    }

    .cristal video {
        border: 8px solid #fff;
        border-radius: 30px;
        height: 170px;
        margin-left: -30%;
        object-fit: cover;
        width: 50%;
        margin-top: -51px;
    }

    #shop .swiper-slide .d_box {
        padding: 10px
    }

    #shop .swiper-slide .d_box .img_box {
        float: none;
        width: 100%;
        display: flex;
    }

    #shop .swiper-slide .d_box .img_box img {
        width: auto;
        max-height: 80px
    }

    #shop .swiper-slide .d_box .img_box img:nth-of-type(2) {
        margin: 10px 0 0 10px;
    }

    #shop .swiper-slide .d_box .detail_box {
        float: none;
        width: 100%;
        padding: 0px !important
    }

    #shop .swiper-slide .d_box .detail_box h1 {
        color: #fff;
        font-size: 20px;
        font-family: 'TT-Trailers';
        -webkit-text-stroke: 1px #00142D;
        text-shadow: -3px 3px 1px #00142D;
        line-height: normal;
    }

    #shop .swiper-slide .d_box .detail_box h2 {
        color: #477DFD;
        font-family: 'Kvant-Menco-Black';
        font-size: 16px;
        line-height: normal;
    }

    #shop .swiper-slide .d_box .detail_box h3 {
        font-family: 'Kvant-Menco-Black';
        font-size: 11px;
        color: #00142D
    }

    #shop .swiper-slide .d_box .detail_box p {
        background: #E9F7FB;
        color: #00142D;
        padding: 10px;
        border-radius: 10px;
        font-size: 10px
    }

    #shop .swiper-slide .d_box .detail_box .price {
        font-size: 14px;
        text-align: right
    }

    #shop .swiper-slide .d_box .p_info {
        background: #FFA3FF;
        border: 2px solid #00142D;
        padding: 18px;
        border-radius: 20px;
        box-shadow: 5px 6px 1px #00142D;
        font-family: 'Kvant-Menco-Black';
        font-size: 15px;
        font-weight: 900;
        line-height: 4.2px;
        color: #fff;
        max-width: 188px;
        -webkit-text-stroke: 1.5px #00142D;
        margin: 0 18px 0 19px;
    }

    #shop .swiper-slide .d_box2 {
        background: #fff;
        border: 2px solid #00142D;
        border-radius: 20px;
        width: 100%;
        padding: 10px 20px;
    }

    #shop .swiper-slide img {
        display: block;
        object-fit: cover;
        width: 100%;
        /*max-width: 240px;*/
        max-width: 320px;
        margin: 0 auto;
    }


    #shop .swiper-slide .d_box .buy {
        font-family: 'Kvant-Menco-Black';
        font-size: 16px;
        background: #A9FF99;
        border: 2px solid #00142D;
        padding: 10px 10px;
        border-radius: 10px;
        box-shadow: 3px 3px 1px #00142D;
        margin: 0px 10px;
        color: #fff;
        -webkit-text-stroke: 1px #00142D;
        max-width: 110px;
    }

    #store .slider .span-box {
        margin: 0 auto;
        display: flex;
    }

    #store .slider .span-box .btn {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
    }

    #store .slider .span-box .btn:first-of-type {
        left: -30px;
    }

    #store .slider .span-box .btn:last-of-type {
        right: -30px;
    }

    #store .slide_list > li .h3_text {
        top: 125px;
        z-index: 1000;
    }

    .merge-panel a:not(:first-child) {
        margin: 0;
        display: block;
    }

    body {
        padding-right: 0 !important;
        text-align: center;
        background-position: top center;
        background-repeat: repeat-x;
        color: #fff;
        font-size: 14px;
        font-family: 'TT-Trailers';
        transition: 0.3s;
        background: url(/public/image/mback_bg.png);
        background-size: 120%;
        min-width: 0px;
        width: 100%;
        overflow-y: scroll;
    }

    .w51 {
        position: relative;
        width: 100%
    }

    #shop .swiper {
        height: 110%;
        max-height: 710px;
        max-width: 91%;
        padding-bottom: 50px;
        width: 90%;
    }

    #shop .swiper-slide {
        align-items: normal !important;
        background: #d2f4ff;
        border: 2px solid #00142d;
        border-radius: 30px;
        display: flex;
        font-size: 18px;
        justify-content: center;
        padding: 0px;
        text-align: center;
        width: 98.6% !important;
    }

    #shop .button_box2 {
        display: flex
    }

    #shop .swiper-slide .d_box2 .button_box2 .shareTo {
        -webkit-text-stroke: 1.5px #00142d;
        color: #fff;
        font-family: kvant-Menco-black;
        font-size: 11px;
        font-weight: 900;
        left: -3.5px;
        letter-spacing: 1%;
        line-height: 31.2px;
        position: absolute;
        text-align: left;
        text-align: center;
        top: 18px;
    }

    #shop .swiper-slide .d_box2 .button_box2 .s_info {
        width: 140px;
        height: 39px;
        display: flex;
        font-family: 'Kvant-Menco-Black';
        z-index: 1;
        position: relative;
        font-size: 11px;
        background: none;
        margin: 0px 5px;
        color: #fff;
        border: none;
        align-items: center;
        justify-content: center;
        -webkit-text-stroke: 1px #00142D;
        width: 54%;
    }

    #shop .swiper-slide .d_box2 .button_box2 .s_info imgimg {
        margin-left: 0px;
        width: 100%
    }

    #shop .swiper-slide .d_box2 .button_box2 .facebook {
        width: 51%;
        height: 39px;
        display: flex;
        font-family: 'Kvant-Menco-Black';
        z-index: 1;
        position: relative;
        font-size: 11px;
        background: none;
        background-size: contain;
        margin: 0px 5px;
        color: #fff;
        border: 0px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        -webkit-text-stroke: 1px #00142D;
    }

    #shop .swiper-slide .d_box2 .button_box2 .event_btn {
        width: 51%;
        height: 39px;
        display: flex;
        font-family: 'Kvant-Menco-Black';
        z-index: 1;
        position: relative;
        font-size: 11px;
        background: none;
        background-size: contain;
        margin: 0px 5px;
        color: #fff;
        border: 0px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        -webkit-text-stroke: 1px #00142D;
    }

    #shop .swiper-slide .d_box2 .button_box2 .facebook img {
        margin-left: 0px;
        width: 100%
    }

    #shop .swiper-slide .d_box2 .button_box2 .event_btn img {
        margin-left: 0px;
        width: 100%
    }

    #shop .swiper-slide .d_box2 .button_box2 .shareTo {
        position: absolute;
        top: 18px;
        color: #fff;
        font-family: 'kvant-Menco-black';
        font-size: 10pt;
        line-height: 5.2px;
        letter-spacing: 1%;
        text-align: left;
        font-weight: 900;
        -webkit-text-stroke: 0px #ffffff;
        left: 4px;
        text-align: center;
    }

    #shop .swiper-slide .d_box2 .button_box2 .event_btn img {
        margin-left: 3px;
        width: auto;
        margin-top: 3px;
        height: 37px;
    }

    #shop .swiper-slide .d_box2 .button_box2 .s_info .shareTo img {
        float: right;
        margin: -6px 0px 0px 7px;
    }

    #shop .swiper-slide .d_box2 .button_box2 .shareTo img {
        /* height: 15px !important; */
        width: 15px;
        margin-top: -12px;
    }
}

/*600 끝*/

#store {
    /*position:absolute;*/
    width: 100%;
    overflow: hidden;
    /*bottom:0px;}*/
    padding-top: 30px;
}

#store_view {
    position: relative !important;
    width: 100%;
    overflow: hidden;
    bottom: 3%;
    padding-top: 10px;
    width: 100%;
    background-color: #fff;
    /* background: url(/public/image/store_view.png) #fff !important; */
}

#store h1 {
    -webkit-text-stroke: 1px #00142d;
    font-family: TT-Trailers;
    font-size: 120px;
    line-height: 0px !important;
    margin-bottom: 115px;
    text-shadow: -4px 4px 1px #000;
}

/* 슬라이드 CSS */
#store .slider {
    font-size: 0;
    height: 100%;
    max-height: 610px;
    max-width: 700px;
    padding: 0 15px;
    text-align: center;
    margin: 0 auto;
}

#store .slider .inner {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    positon: relative;
}

#store .slide_list > li {
    position: relative;
    width: 20%;
    height: 444px;
    margin: 0;
    font-size: 20px;
    line-height: 150px;
    color: #000;
    text-align: center;
    z-index: 0;
    transform: scale(1);
    transition: all 0.3s;
    margin-top: 20px;
    cursor: pointer
}

#store .slide_list > li img {
    width: 100%
}

#store .slide_list > li .h1_text {
    background: #fff;
    max-width: 87.7px;
    border-radius: 50px;
    /*padding:10px 5px;*/
    font-family: 'Kvant-Menco-Black';
    color: #00142D;
    font-size: 11.4px;
    position: absolute;
    left: 32%;
    width: 150px;
    top: -12px;
    height: 41.1px;
    line-height: 41px;
    text-align: center;
    border: 1px solid #00142D;
    box-shadow: 0px 3px 1px #00142D;
    cursor: pointer
}


#store .slide_list > li .h2_text {
    -webkit-text-stroke: 1px #00142D;
    color: #fff;
    font-family: 'Kvant-Menco-Black';
    background: #FFA3FF;
    border: 1.5px solid #00142d;
    position: absolute;
    left: 10%;
    padding: 10px;
    width: 80%;
    bottom: 200px;
    line-height: 30px;
    border-radius: 20px;
    word-break: keep-all;
    font-size: 25px;
    white-space: normal;
    word-wrap: break-word;
}

#store .slide_list > li .h3_text {
    -webkit-text-stroke: 0.5px #fff;
    color: #00142D;
    font-family: 'Kvant-Menco-Black';
    position: absolute;
    width: 100%;
    bottom: 104px;
    font-weight: 700;
    text-shadow: 1px 1px 1px #000
}

#store .slide_list > li .h4_text {
    -webkit-text-stroke: 0.5px #fff;
    color: #00142D;
    font-family: 'Kvant-Menco-Black';
    background: #A9FF99;
    border: 1.5px solid #00142d;
    position: absolute;
    left: 10%;
    padding: 10px;
    width: 80%;
    bottom: 200px;
    line-height: 31px;
    border-radius: 20px;
    word-break: keep-all;
    white-space: normal;
    word-wrap: break-word;
    display: none;
    align-items: center
}

#store .slide_list > li .h4_text img {
    width: 20px;
    height: 20px;
    margin: 20px 10px;
}

#store .slide_list > li:hover .h1_text {
    background: #00142D;
    max-width: 87.7px;
    border-radius: 50px;
    /*padding:10px 5px;*/
    font-family: 'Kvant-Menco-Black';
    color: #fff;
    font-size: 11.04px;
    position: absolute;
    left: 32%;
    top: -12px;
    height: 41.1px;
    line-height: 41px;
    text-align: center;
    border: 1px solid #00142D;
    box-shadow: 1px 3px 1px 0 #fff,
    2px 5px 0 0 #000;
}

#store .slide_list > li:hover .h2_text {
    display: none
}

#store .slide_list > li:hover .h3_text {
    display: none
}

#store .slide_list > li:hover .h4_text {
    display: block
}

/* 영역별 크기 설정 */
#store .slide_list > li.swiper-slide-next,
#store .slide_list > li.swiper-slide-prev,
#store .slide_list > li.swiper-slide-duplicate-next,
#store .slide_list > li.swiper-slide-duplicate-prev {
    z-index: 50;
    transform: scale(1.3);
    margin-top: -30px;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
}

#store .slide_list > li.swiper-slide-active,
#store .slide_list > li.swiper-slide-duplicate-active {
    z-index: 100;
    transform: scale(1.8);
    margin-top: -32px;
    -webkit-transform: scale(1.8);
    -moz-transform: scale(1.8);
    -ms-transform: scale(1.8);
    -o-transform: scale(1.8);
}

#store .slider {
    position: relative;
}

#store .slider .btn {
    position: absolute;
    top: 0;
    z-index: 1000;
}


#store_view .big_img_area {
    max-width: 520px;
    width: 100%
}

#store_view .swiper {
    width: 100%;
    height: 110%;
    padding-bottom: 50px
}

#store_view .swiper-slide {
    align-items: center;
    background: #fff;
    display: flex;
    font-size: 18px;
    justify-content: center;
    text-align: center;
    /*background: url("/public/image/product_boxbg.png") no-repeat;*/
    padding: 0px;
    background-size: contain;
    height: auto;
}

#store_view .swiper-slide img {
    display: block;
    object-fit: cover;
    width: 100%;
    margin-left: 20px;
}

.small_img3 {
    display: none
}

#store_view .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 0px !important;
}

#store_view .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 19px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 19px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #fff);
    border: 1px solid #00142D;
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    box-shadow: 2px 2px 1px #00142D;
    margin-left: 12px;
}

#store_view .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

/* 화살표 */
#store .slider .btn {
    display: inline-block;
    width: 64px;
    height: 64px;
    top: 40%;
    /*margin:0px 210px;*/
    background: url('/public/image/arrow.png') center center no-repeat;
    background-size: cover;
    text-indent: -999em;
    cursor: pointer;
}

#store .slider .btn.btn_prev {
    left: 0%;
    transform: rotateY(180deg);
}

#store .slider .btn.btn_next {
    right: 0%;
}

/*store_view popup*/

.formPopup {
    display: none; /* 기본적으로 팝업을 숨깁니다 */
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    padding-top: 60px;
    overflow: auto; /* 페이지 넘침 방지 */
    z-index: 10;

}

.formPopup form {
    background-color: white;
    margin: 50px auto 0;
    padding: 0px 100px 50px;
    border: 3px solid #00142D;
    width: 80%;
    max-width: 500px;
    border-radius: 20px;
    max-width: 544px;
    position: relative;
}

.formPopup form:not(p) {
    font-family: 'Kvant-Menco-Black';
}

.formPopup form p {
    font-size: 53px;
    font-family: 'TT-Trailers';
    line-height: 68.9px;
    font-weight: 800;
    text-align: center;
    text-shadow: -2px 2px 0 #00142D;
    -webkit-text-stroke: 1px #00142D;
}


/* 입력창 및 레이블 스타일 */
.formPopup form label {
    text-align: left;
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
    color: #477DFD;
    font-size: 18px;
    line-height: 19.2px;
}

.formPopup form input, form textarea {
    /*width: 100%;*/
    padding: 15px 10px;
    /*border: 1px solid #F1F1F4;*/
    /*border-radius: 8px;*/
    /*background-color: #F1F1F4;*/
    /*max-height: 40px;*/
}

.formPopup form input:not(:last-of-type) {
    margin-bottom: 24px;
}

.formPopup form input:last-of-type {
    /*margin-bottom: 48px;*/

}

/* 버튼 스타일 */
.formPopup form .formExit {
    position: absolute;
    top: 27px;
    right: 27px;
    background: none;
    border: none;
}

.formPopup button:not(.viewButton) {
    display: inline-block;
    padding: 16px 0;
    margin: 0;
    cursor: pointer;
    letter-spacing: 1%;
    font-family: 'kvant-Menco-black';
    font-weight: 900;
    font-size: 26px;
    line-height: 31.2px;
    text-align: center;
    max-width: 160px;
    width: 100%;
    -webkit-text-stroke: 1.8px #00142D;
    color: #fff;
    border: 2px solid #00142D;
    border-radius: 20px;
    position: relative;
    box-shadow: 5px 6px 0 0 #00142D;
}

.formPopup button[type="reset"] {
    background-color: #EDEDF2;
    margin-right: 10px;

}

.formPopup button[type="submit"] {
    background-color: #477DFD;
}
.checkbox-list li label{
    display: flex;
    align-items: center;
}


@media (max-width: 1320px) {
    #store .slider .inner {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        padding: 292px 0;
    }

    #store .slide_list > li {
        position: relative;
        width: 20%;
        height: 444px;
        margin: 0;
        font-size: 20px;
        line-height: 150px;
        color: #000;
        text-align: center;
        z-index: 0;
        transform: scale(1);
        transition: all 0.3s;
        margin-top: 20px
    }

    #store .slide_list > li.swiper-slide-active .h1_text {
        width: 80%;
        left: 26%
    }
}

@media (max-width: 833px) {
    #store .slider .inner {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        padding: 330px 0;
    }

    #store .slide_list > li {
        position: relative;
        width: 33.3%;
        height: 456px;
        margin: 0;
        font-size: 16px;
        line-height: 150px;
        color: #000;
        text-align: center;
        z-index: 0;
        transform: scale(1);
        transition: all 0.3s;
        margin-top: 20px
    }
}

/*760 시작*/
@media (max-width: 760px) {
    .store_bg {
        overflow: hidden
    }

    #store {
        position: absolute;
        bottom: 0px;
        width: 100%;
        overflow: hidden;
        max-height: 100%;
        padding-top: 0;
    }

    #store h1 {
        font-family: 'TT-Trailers';
        font-size: 50px;
        text-shadow: -4px 4px 1px #000;
        -webkit-text-stroke: 1px #00142D;
    }

    #store .slider .btn {
        display: inline-block;
        width: 64px;
        height: 64px;
        margin: 0px 50px;
        background: url('/public/image/arrow.png') center center no-repeat;
        background-size: cover;
        text-indent: -999em;
        cursor: pointer;
    }

    #store .slider .inner {
        padding: 316px 0 0px;
    }

    #store .slide_list > li {
        position: relative;
        width: 60%;
        height: 644px;
        margin: 0;
        font-size: 16px;
        line-height: 150px;
        color: #000;
        text-align: center;
        z-index: 0;
        transform: scale(1);
        transition: all 0.3s;
        margin-top: 20px
    }

    #store .slide_list > li img {
        width: 80%;
    }

    #store .slide_list > li.swiper-slide-active .h1_text {
        width: 80%;
        left: 17%;
    }

    #store .slider {
        padding: 0px
    }

    #store .slide_list > li .h2_text {
        -webkit-text-stroke: 1px #00142D;
        color: #fff;
        font-family: 'Kvant-Menco-Black';
        background: #FFA3FF;
        height: 88px;
        border: 1.5px solid #00142d;
        position: absolute;
        left: 20%;
        padding: 10px 20px;
        width: 60%;
        top: 100px;
        line-height: 1.6em;
        border-radius: 20px;
        word-break: keep-all;
        white-space: normal;
        word-wrap: break-word;
        font-size: 10pt;
    }

    #store .slide_list > li .h4_text {
        -webkit-text-stroke: 0.5px #fff;
        color: #00142D;
        font-family: 'Kvant-Menco-Black';
        background: #A9FF99;
        height: 60px;
        border: 1.5px solid #00142d;
        position: absolute;
        left: 20%;
        padding: 20px;
        width: 60%;
        top: 150px;
        line-height: 20px;
        border-radius: 20px;
        word-break: keep-all;
        white-space: normal;
        word-wrap: break-word;
        display: none;
        align-items: center;
        font-size: 10pt
    }

    #store .slide_list > li .h4_text img {
        width: 10px;
        height: 10px;
        margin: 0px 0px;
    }

    #store .slider .span-box {
        position: absolute;
        top: 0;
        z-index: 1000;
        display: inline-block;
        margin: 0 auto;
        width: 70%;
    }
}

#project .btn_list {
    left: 50%;
    margin-left: -200px;
    padding-top: 8%;
    position: absolute;
    width: 400px;
    z-index: 1;
    top: 50% !important;
    padding-top: 0px !important;
}

/*760 끝*/
/*1920 시작*/
@media (min-width: 1921px) {
    body {
        background-size: 100% 100%;
    }
}

/*1920 끝*/
button, a, input, select, textarea, span {
    outline: none !important;
    transition: 0.3s;
    text-decoration: none;

}

button {
    padding: 0;
    cursor: pointer;
}

a:hover {
    text-decoration: none;
}

.w-b, .w-a, .w-ba {
    position: relative;
    z-index: 1;
}

.w-b:before,
.w-a:after,
.w-ba:before,
.w-ba:after {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: -1;
}


:root {
    --yellow: #cb4d30;
    --orange: #ffcc00;
}

.font-small {
    font-size: 10px;
}

.font-s-12 {
    font-size: 12px !important;
}

.font-bold {
    font-family: noto-sans-bold;
}

.font-black {
    font-family: noto-sans-black;
}

.text-yellow {
    color: var(--yellow) !important;
}

.text-yellow-f {
    color: #ffdf84 !important;
}

.text-red {
    color: #ff9159 !important;
}

.table-layout-fixed {
    table-layout: fixed;
}

.min-h-auto {
    min-height: auto !important;
}


.dflex-ac-js {
    display: flex !important;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.dflex-ac-je {
    display: flex !important;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.dflex-ae-jc {
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
}

.dflex-as-jc {
    display: flex !important;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
}

.dflex-acs-jc {
    display: flex !important;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.wrapper {
    width: 100%;
    min-height: 100%;
    min-width: 1200px;
    position: relative;
    overflow: hidden;
}

.wrapper:before,
.wrapper:after {
    width: 100%;
    min-width: 1920px;
    height: 900px;
    left: 50%;
    transform: translateX(-50%);
    top: 150px;
    background-repeat: no-repeat;
}

.wrapper:before {
    background-position: left top;
    /*background-image: url(/public/image/img/bg/left-bg.png);*/
    opacity: 0.7;
    left: 49%;
}

.wrapper:after {
    background-position: right top;
    /*background-image: url(/public/image/img/bg/right-bg.png);*/
    opacity: 0.4;
    left: 52%;
}

.bs-ul {
    list-style: none;
    margin-bottom: 0;
    width: auto;
    display: table;
    padding: 0;
}

.bs-ul li {
    list-style: none;
    width: auto;
    height: auto;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.container {
    position: relative;
    max-width: 1280px;
    padding: 0;
}

.container1 {
    position: relative;
    max-width: 1140px !important;
}

.w51 {
    position: relative;
}


.loading-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    /*background-image: url(/public/image/img/icon/loading-transparent.gif);*/
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    z-index: 1999;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}

.loading-wrapper.active {
    opacity: 1;
    pointer-events: auto;
}


/* Site Button */


.btn-yellow {
    color: rgba(0, 0, 0, 1.0);
    background: #fe8c00; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f83600, #fe8c00); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f83600, #fe8c00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    border: none;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 1.0);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.btn-red {
    color: #ffffff;
    background: #52c234; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3e8f28, #52c234); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3e8f28, #52c234); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: none;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.btn-green {
    color: #ffffff;
    background: #396afc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: none;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.btn-gray {
    color: #ffffff;
    background-color: #8c8a8a;
    background-image: linear-gradient(#8c8a8a, #575757);
    border: none;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.btn-yellow span,
.btn-red span,
.btn-green span,
.btn-gray span {
    transition: 0s;
}

.btn-yellow:before,
.btn-red:before,
.btn-green:before,
.btn-gray:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: 0.3s;
    z-index: -1;
    border-radius: 2px;
    opacity: 0;
    transform: translateY(-100%);
}


.btn-yellow:before {
    background: #f83600; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fe8c00, #f83600); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fe8c00, #f83600); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.btn-red:before {
    background: #3e8f28; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #52c234, #3e8f28); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #52c234, #3e8f28); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.btn-green:before {
    background: #2948ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #396afc, #2948ff); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #396afc, #2948ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn-gray:before {
    background-image: linear-gradient(#b1b1b1, #777777);
}

/*1201 시작*/
@media (min-width: 1201px) {

    .btn-yellow:hover:before,
    .btn-red:hover:before,
    .btn-green:hover:before,
    .btn-gray:hover:before {
        opacity: 1;
        transform: translateY(0);
    }

    .btn-yellow:hover {
        color: #ffffff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0), 0 0 15px rgba(255, 187, 0, 0.25);
    }

    .btn-red:hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0), 0 0 15px rgba(205, 63, 0, 0.25);
    }

    .btn-green:hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0), 0 0 15px rgba(23, 134, 204, 0.25);
    }

    .btn-gray:hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 1.0), 0 0 15px rgba(255, 255, 255, 0.25);
    }

}

/*1201 끝*/

/* Header Section */

.header-section {
    width: 98%;
    position: relative;
    z-index: 99;
    transition: 0.3s;
    border-bottom: 3px solid #000;
    background: #64c3fd96;
    border-radius: 0px 0px 30px 30px;
    left: 1%;
    right: 1%;
}

.header-section:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.95);
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(0, 0, 0, 0) 51% 100%);
    box-shadow: 0 2px 0 var(--yellow);
    transition: 0s;
    opacity: 0;
}


.header-top {
    min-height: 100px;
    position: relative;
    z-index: 9;
}


.logo {
    left: 50px;
    top: 42px;
    position: Absolute;
}

.modal .logo {
    top: 0;
}


.logo img {
    height: 73px;
}

.logo .logo-img {
    opacity: 0;
}

.logo .diamond {
    width: 59.34%;
    top: 13%;
    left: -20%;
    animation: lgDiamondAnim 10s ease infinite;
}

@keyframes lgDiamondAnim {
    0% {
        opacity: 0;
        transform: translateY(-25%);
    }
    8% {
        opacity: 0;
        transform: translateY(-25%);
    }
    15% {
        opacity: 1;
        transform: translateY(5%);
    }
    25% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}


.logo .diamond2 {
    width: 124px;
    top: 0%;
    left: -23%;
    animation: lgDiamondAnim2 10s ease infinite;
}

@keyframes lgDiamondAnim2 {
    0% {
        opacity: 0;
        transform: translateY(-25%);
    }
    8% {
        opacity: 0;
        transform: translateY(-25%);
    }
    15% {
        opacity: 1;
        transform: translateY(5%);
    }
    25% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.logo .star {
    width: 100.67%;
    top: 15.25%;
    right: -245px;
    transform-origin: bottom center;
    animation: lgStarAnim 10s ease infinite;
    height: 65px !important;
    width: 124px !important;
}

@keyframes lgStarAnim {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    15% {
        opacity: 0;
        transform: scale(0);
    }
    25% {
        opacity: 1;
        transform: scale(1);
    }
    95% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.logo .items {
    width: 60%;
    top: 26.25%;
    left: 4%;
    transform-origin: bottom center;
    animation: lgItemsAnim 10s ease infinite;
}

@keyframes lgItemsAnim {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    12% {
        opacity: 0;
        transform: scale(0);
    }
    22% {
        opacity: 1;
        transform: scale(1);
    }
    95% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.logo .crown {
    width: 50px;
    top: 21%;
    left: 51%;
    animation: lgCrownAnim 10s ease infinite;
}

@keyframes lgCrownAnim {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.logo .rising {
    width: 100%;
    top: 0px;
    animation: lgRisingAnim 10s ease infinite;
}

@keyframes lgRisingAnim {
    0% {
        opacity: 0;
        transform: translateY(25%);
    }
    5% {
        opacity: 0;
        transform: translateY(25%);
    }
    15% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.logo .slot {
    width: 33.89%;
    bottom: 0.5%;
    left: 2.5%;
    animation: lgSlotAnim 10s ease infinite;
}

@keyframes lgSlotAnim {
    0% {
        opacity: 0;
        transform: translateY(-25%);
    }
    5% {
        opacity: 0;
        transform: translateY(-25%);
    }
    15% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}


/*Main*/

.wrapper {
    background: url("/public/image/img/bg/back.jpg") center top;
    min-height: 995px
}

.intig {
    cursor: pointer;
    border: 1px solid #555
}

.intig:hover {
    filter: brightness(1.3);
    border: 1px solid #eee;
    box-shadow: 1px 1px 5px #ddd
}

.walbtn {
    height: 53px !important;
    border-radius: 40px;
    border: 2px solid #333;
    background: #fff;
    width: 197px !important;
    font-family: NanumSquareRound;
    font-size: 16px;
    font-weight: 800;
    line-height: 32px;
    text-align: center;
    color: #333;
    transition: 0.3s;
}

.walbtn:hover {
    height: 53px !important;
    border-radius: 40px;
    border: 2px solid #d02b69;
    background: #fff;
    width: 197px !important;
    font-family: NanumSquareRound;
    line-height: 32px;
    text-align: center;
    color: #d02b69;
    transition: 0.3s;
}

.login button {
    background: url(/public/image/btn_login_1.png);
    border: 0px;
    width: 76px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat;
}

.logout button {
    border: 0px;
    width: 76px;
    height: 75px;
}
.logout button.mypage{
    margin-left: 10px;
    width: 74px;
    background: url(/public/image/btn_join_1.png);
    background-size: cover !important;
    background-repeat: no-repeat;
}
.logout {
    position: absolute;
    right: 50px;
    top: 40px;
}
.mypage{
    background-image: url("/public/image/btn_join_1.png");
    width: 76px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat;
}

.X-Social{
    background-image: url("/public/image/x_btn.png") !important;
    width: 76px;
    height: 75px;
    background-repeat: no-repeat !important;
    background-color: transparent; /* 배경을 투명으로 설정 */
}

/*.login button,.logout button,.logout button.X-Social{*/
/*    border:0px;*/
/*    background-size: cover !important;*/
/*    background-repeat: no-repeat;*/
/*}*/

/*.logout button.X-Social{*/
/*    width: 54px;*/
/*    height: 55px;*/
/*}*/
/*.logout button.X-Social{*/
/*    width: 55px;*/
/*    height: 56px;*/
/*    background-size: cover !important;*/
/*}*/

.balloon {
    z-index: 1;
    animation: moveUpDown 5s infinite;
    margin-left: -250px;
    margin-top: 100px;
    z-index: -1;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

.balloon2 {
    position: absolute;
    top: 100px;
    left: 55%;
    z-index: -2;
    animation: moveUpDown 5s infinite;
    width: 300px !important
}

.gnb {
    background: url("/public/image/menubg.png") no-repeat;
    width: 449px;
    height: 75px;
    position: absolute;
    top: 42px;
    left: 50%;
    margin-left: -225px;
    padding-left: 0 !important;
    z-index: 1
}

.gnb ul{
    display: flex !important;
    justify-content: center !important;
    margin-right: 8px;
}
/* ! shop 추가시 바로 아래 코드 제거 !*/
.visibleFalse{
    display: none;
}
.gnb li {
    float: left;
    color: #555
}

.gnb li a {
    font-family: TT-Trailers;
    font-size: 26px;
    font-weight: 800;
    line-height: 70px;
    text-align: center;
    padding: 10px 25px;
    color: #00142D;
}

.gnb li a:hover {
    font-family: TT-Trailers;
    font-size: 26px;
    font-weight: 800;
    line-height: 70px;
    text-align: center;
    padding: 10px 25px;
    color: #477DFD;
}

.gnb .achive {
    font-family: TT-Trailers;
    font-size: 26px;
    font-weight: 800;
    line-height: 70px;
    text-align: center;
    padding: 10px 25px;
    color: #477DFD;
}


article.bpart button {
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
    max-width: 70px;

}

article.bpart img {
    transition: opacity 0.3s ease; /* 부드러운 트랜지션 효과 */
    width: 100%; /* 버튼에 맞게 이미지 크기 조정 */
    height: auto; /* 비율 유지 */
}

/*article.bpart button:hover img {*/
/*    opacity: 0; !* 호버 시 이미지 투명하게 *!*/
/*}*/

/*article.bpart button:hover::after {*/
/*    content: ''; !* 호버 시 추가 콘텐츠 생성 *!*/
/*    background-image: url('/public/image/new_btn_ico02.png'); !* 호버 이미지 *!*/
/*    background-size: contain; !* 이미지 크기 조정 *!*/
/*    background-repeat: no-repeat; !* 반복하지 않음 *!*/
/*    position: absolute; !* 절대 위치 설정 *!*/
/*    top: 0; !* 상단 맞춤 *!*/
/*    left: 0; !* 좌측 맞춤 *!*/
/*    width: 100%; !* 전체 폭 *!*/
/*    height: 100%; !* 전체 높이 *!*/
/*    opacity: 1; !* 완전 불투명 *!*/
/*    transition: opacity 0.5s ease; !* 부드러운 트랜지션 효과 *!*/
/*    max-width: 44px;*/
/*    padding: 10px;*/
/*}*/

/*article.bpart button:hover::after {*/
/*    opacity: 1; !* 호버 시 완전 불투명으로 설정 *!*/
/*}*/


.bpart {
    position: absolute;
    bottom: 40px;
    width: 100%;
}

.bpart button:not(:first-of-type) {
    margin-left: 4.47%;
}

.left_cloude {
    position: absolute;
    left: 110px;
    top: 135px;
    z-index: 1;
    animation: moveLeft 8s infinite;
}

@keyframes moveLeft {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-30px);
    }
    100% {
        transform: translateX(0);
    }
}

.right_cloude {
    position: absolute;
    right: 110px;
    top: 135px;
    z-index: 1;
    animation: moveLeft 8s infinite;
}

@keyframes moveLeft {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-30px);
    }
    100% {
        transform: translateX(0);
    }
}

.bottom {
    position: fixed;
    left: 0px;
    bottom: -190px;
    z-index: 1;
    animation: slide-down 1s forwards;
}

@keyframes slide-down {
    from {
        bottom: -90px;
    }
    to {
        bottom: -210px;
    }
}

.down_cloud {
    position: absolute;
    left: 0px;
    bottom: -180px;
    animation: slide-up 30s forwards;
    z-index: 1;
}

@keyframes slide-up {
    from {
        bottom: -80px;
    }
    to {
        bottom: -70px;
    }
}

.rainbow {
    position: absolute;
    left: 300px;
    bottom: 95px;
    animation: slide-left 1s forwards;
    z-index: 1;
}

@keyframes slide-left {
    from {
        left: 300px;
    }
    to {
        left: 330px;
    }
}


.navi_area button {
    border: 0px;
    background: none
}

.a_area {
    position: absolute;
    top: 353px;
    left: 33px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.a_areabox {
    position: absolute;
    top: 430px;
    left: 167px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 2s infinite;
}

@keyframes moveUpDown2 {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.a_areabox img {
    width: 100%
}

.a_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.a_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.b_area {
    position: absolute;
    top: 465px;
    left: 135px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.b_areabox {
    position: absolute;
    top: 505px;
    left: 285px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.b_areabox img {
    width: 100%
}

.b_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.b_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}


.c_area {
    position: absolute;
    top: 570px;
    left: 265px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.c_areabox {
    position: absolute;
    top: 597px;
    left: 425px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.c_areabox img {
    width: 100%
}

.c_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.c_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}


.d_area {
    position: absolute;
    top: 595px;
    left: 372px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.d_areabox {
    position: absolute;
    top: 687px;
    left: 522px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.d_areabox img {
    width: 100%
}

.d_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.d_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}


.e_area {
    position: absolute;
    top: 255px;
    left: 175px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.e_areabox {
    position: absolute;
    top: 287px;
    left: 334px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.e_areabox img {
    width: 100%
}

.e_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.e_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.f_area {
    position: absolute;
    top: 64px;
    left: 356px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.f_areabox {
    position: absolute;
    top: 210px;
    left: 525px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.f_areabox img {
    width: 100%
}

.f_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.f_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.g_area {
    position: absolute;
    top: 317px;
    left: 423px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.g_areabox {
    position: absolute;
    top: 310px;
    left: 530px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.g_areabox img {
    width: 100%
}

.g_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.g_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.h_area {
    position: absolute;
    top: 392px;
    left: 419px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.h_areabox {
    position: absolute;
    top: 440px;
    left: 530px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.h_areabox img {
    width: 100%
}

.h_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.h_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.i_area {
    position: absolute;
    top: 226px;
    left: 618px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.i_areabox {
    position: absolute;
    top: 250px;
    left: 730px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.i_areabox img {
    width: 100%
}

.i_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.i_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.j_area {
    position: absolute;
    top: 387px;
    left: 550px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.j_areabox {
    position: absolute;
    top: 440px;
    left: 680px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.j_areabox img {
    width: 100%
}

.j_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.j_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.k_area {
    position: absolute;
    top: 290px;
    left: 875px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.k_areabox {
    position: absolute;
    top: 400px;
    left: 980px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.k_areabox img {
    width: 100%
}

.k_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.k_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}

.l_area {
    position: absolute;
    top: 574px;
    left: 633px;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
}

.l_areabox {
    position: absolute;
    top: 610px;
    left: 830px;
    z-index: 3;
    transition: 0.3s;
    cursor: pointer;
    width: 50px;
    animation: moveUpDown2 3s infinite;
}

.l_areabox img {
    width: 100%
}

.l_areabox .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0
}

.l_areabox .photo img {
    padding: 5px;
    border-radius: 100% !important
}


.con_popimg {
    width: 100%;
    border-radius: 10px
}

.ment {
    text-align: left;
    min-height: 400px
}


.h1_text {
    text-align: left;
    font-size: 20pt;
    border-bottom: 1px solid #e7e7e740;
    padding-bottom: 10px;
}

/*belly*/

.belly-yellow {
    background-color: #FFDB87;
    padding-top: 187px;
}

.belly-Y, .belly-B {
    background-color: #fff;

}
.belly-Y figure{
    margin: 0;
}
.belly-blue {
    background-color: #3A78FF;
    margin-bottom: 68px;
    padding-top: 187px;
}

.belly-yellow img, .belly-blue img {
    width: 100%;
    display: block;
}

.belly-yellow2, .belly-blue2 {
    display: block;
    margin: 0 auto;
}



.belly-yellow2 img, .belly-blue2 img {
    width: 100%;
}

footer {
    width: 100%;
    height: 182px;
    /*min-height:182px;*/
    background: #00142D;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    position: relative;
    border-top: 2px solid #000
}

/*merch*/

.merch-section li {
    text-align: left;
    font-size: 15pt;
    clear: both;
    border-bottom: 1px solid #dddddd57;
    overflow: hidden;
    height: 50px;
    padding: 10px 0px;
}

.big_img {
    border: 0px solid #000;
    border-radius: 31px;
    height: auto;
    overflow: hidden;
    padding: 0px;
    width: 500px;
    width: 479px !important;
    margin: 10px 34px 40px 0px;
}

.cate {
    max-width: 1310px;
    width: 100%;
    height: 100px;
    border-radius: 16px;
    border: 1px solid #000000;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    color: #333;
    background: #fff;
    box-shadow: 5px 5px 0px #0000004f;
}

.cate li {
    float: left;
    padding: 40px 30px;
    font-family: 'TT-Trailers';
    color: #000;
    font-weight: 500
}

.cate li:hover {
    font-weight: 600;
    color: #477dfd
}

.cate .achive {
    font-weight: 600;
    color: #477dfd
}

.g-name h3 {
    font-size: 17px;
    font-weight: 600;
    font-family: 'TT-Trailers';
}

.g-name h4 {
    font-size: 24px;
    font-weight: 400;
    font-family: 'TT-Trailers';
    color: #477dfd
}

.g-name h4 s {
    font-size: 18px;
    font-weight: 400;
    font-family: 'TT-Trailers';
    color: #777
}

.cart {
    border: 1px solid #183153;
    background: #fff;
    border-radius: 5px;
    width: 22px;
    padding: 3px;
    margin-left: 10px
}

.cart img {
    width: 100%
}

.merch-section {
    max-width: 1140px;
    margin: 0 auto;
    padding: 159px 0px;
    color: #333;
    font-family: 'TT-Trailers';
    overflow: hidden;
}

.merch-section li {
    text-align: left;
    font-size: 15pt;
    clear: both;
    border-bottom: 1px solid #dddddd57;
    overflow: hidden;
    height: 50px;
    padding: 10px 0px;
}

.merch-section .box h1 {
    font-size: 30px;
    text-align: left
}

.merch-section .box p {
    padding-top: 0px;
    text-align: left;
}

.merch-section .price {
    color: #021E41;
    font-size: 45px;
    clear: both;
    font-family: 'Kvant-Menco-Black';
    float: right;
}

#store_view .merch-section {
    max-width: 1280px;
}

#store_view .box h1 {
    font-size: 65px;
    line-height: 60px;
    font-weight: 800;
    font-family: 'TT-Trailers';
    -webkit-text-stroke: 1px #00142D;
    color: #fff;
    text-shadow: -3px 3px 0 #00142D;
    margin-bottom: 30px;
}

#store_view .box h5 {
    font-size: 30px;
    font-family: 'Kvant-Menco-Black';
    color: #477DFD;
    line-height: 36px;
    margin-bottom: 12px;
    max-width: 580px;
}

#store_view .box p {
    color: #00142d;
    font-family: Kvant-Menco-Black;
    font-size: 20px;
    font-weight: 900;
    line-height: 21px;
}

#store_view .price_box {
    padding-top: 30px;
    clear: both;
}

#store_view .box {
    position: relative;
    margin-right: 5px;
    top: 30px;
}

.hoverable {
    position: relative;
    display: inline-block; /* inline-block으로 설정하여 크기 조절 가능 */
    bottom: 15px;
}

.hoverable img {
    transition: opacity 0.3s; /* 부드러운 효과를 위한 트랜지션 */
    position: absolute;
    top: 0;
    left: 0;
}

.hoverable .hover {
    opacity: 0; /* 기본적으로 호버 이미지는 보이지 않음 */
}

.hoverable:hover .default {
    opacity: 0; /* 호버 시 기본 이미지를 투명하게 만듦 */
}

.hoverable:hover .hover {
    opacity: 1; /* 호버 시 호버 이미지를 보이게 함 */
}

.price_box .packNum #number {
    position: absolute;
    top: -2px;
    left: 67px;
}

.price_box .packNum #increment {
    left: 10px;
}

.w50 {
    width: 50%;
    position: relative;
    margin-left: 58px;
}

.fl {
    float: left
}

.fr {
    float: right;
}

.packNum {
    display: flex;
    font-size: 42px;
    margin-top: 20px;
    position: relative;

}

.packNum span:nth-of-type(odd) {
    display: flex;
    height: 30px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #333;
    cursor: pointer;
}

.packNum span:nth-of-type(2), .packNum span:nth-of-type(odd) {
    align-items: center;
    color: #333;
    display: flex;
    flex-direction: column;
    height: 30px;
    justify-content: center;
    margin-left: 58px;
    margin-top: -15px;
}

.exchain_btn {
    background: none;
    border: 0px;
    margin-top: 15px;
}

.exchain_btn img {
    width: 100%
}

/* merge */
#project {
    padding: 10px;
}
.merge-bg{
    padding-bottom: 40px !important;
    height: 100% !important;
    box-sizing: border-box;
}
#project h2 {
    font-family: 'TT-Trailers';
    -webkit-text-stroke: 1px #00142D;
    padding-left: 2px;
    text-align: left;
    font-size: 45px;
    margin: 0 0 44px 0;
    min-width: 360px;
    text-shadow: -2px 2px 0px #00142D;
}

#project .puzy_icon li {
    width: 16.6%;
    float: left
}

#project .puzy_icon li img {
    width: 100%
}

.project_bg {
    width: 100%;
    background-image: url(/public/image/merge.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.merge-section {
    max-width: 1280px;
    margin: 210px auto 0;
}

.merge-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    /*width: calc(100% + 22px);*/
    /*margin: 0 -11px;*/
    max-width: 1280px;
    text-align: left;
}

.merge-btn {
    float: left;
}

.merge-btn img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.merge-btn .g-panel {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 20px 22px ;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.merge-text{
    width: 100%;
    text-align: left;
}
.merge-text p{
    font-size: 26px;
    line-height: 60px;
    font-weight: 800;
    color: white;
    -webkit-text-stroke: 1px #00142D;
    margin: 0;
}
.merge-text span{
    font-family: 'kvant-Menco-black';
    color: #00142D;
    font-size: 16px;
    font-weight: 900;
    line-height: 19.2px;
}

.merge-btn p > span {
    color: #00142D;
    font-family: 'TT-Trailers';
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    -webkit-text-stroke: 0px #00142D;
}

#project .search_box {
    width: 309px;
    height: 55px;
    float: right;
    border: 2px solid #00142D;
    border-radius: 15px;
    display: flex
}

#project .search_box input {
    width: 245px;
    border: 0px;
    font-family: 'Kvant-Menco-Medium';
    font-size: 18px;
    border-radius: 15px 0px 0px 15px;
    padding-left: 20px;
}

#project .search_box input::placeholder {
    color: #B3B6B9;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
}

#project .search_box button {
    background: #FE90FE;
    height: 55px;
    width: 65px;
    border: 0px;
    border-radius: 0px 15px 15px 0px;
    border-left: 2px solid #00142D
}

#project .btn_list {
    clear: both;
    padding-top: 500px;
}

#project .btn_list button {
    background: none;
    width: 80%;
    max-width: 386px;
    border: 0px
}

#project .btn_list button img {
    margin-left: 10px;
    max-width: 100%;
}

.puzy_icon {
    overflow: hidden; /* 부모 요소가 자식 요소를 잘라내도록 설정 */
    margin-top: -60px;
}

.puzy_icon ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 407px;
}

.puzy_icon li {
    opacity: 0; /* 초기 상태: 투명 */
    transform: translateY(-20px); /* 위에서 약간 이동 */
    animation: fadeIn 0.5s forwards; /* 애니메이션 적용 */
}


@keyframes fadeIn {
    to {
        opacity: 1; /* 최종 상태: 불투명 */
        transform: translateY(0); /* 원래 위치로 이동 */
    }
}

.puzy_icon li:nth-child(1) {
    animation-delay: 0s;
    margin-top: 93px;
}

.puzy_icon li:nth-child(2) {
    animation-delay: 0.2s;
    margin-top: 13px;
}

.puzy_icon li:nth-child(3) {
    animation-delay: 0.4s;
    margin-top: 39px;
}

.puzy_icon li:nth-child(4) {
    animation-delay: 0.6s;
}

.puzy_icon li:nth-child(5) {
    animation-delay: 0.8s;
    margin-top: 15px;
}

.puzy_icon li:nth-child(6) {
    animation-delay: 1s;
    margin-top: 104px;
}

@media (max-width: 720px) {
    /*#project .search_box {*/
    /*    width: 100%;*/
    /*    height: 55px;*/
    /*    float: right;*/
    /*    border: 2px solid #00142D;*/
    /*    border-radius: 10px;*/
    /*    display: flow*/
    /*}*/

    /*#project .search_box input {*/
    /*    height: 100%;*/
    /*    width: 86%;*/
    /*    border: 0px;*/
    /*    font-family: 'Kvant-Menco-Medium';;*/
    /*    border-radius: 10px;*/
    /*    padding-left: 20px;*/
    /*    float: left*/
    /*}*/

    /*#project .search_box button {*/
    /*    background: #FE90FE;*/
    /*    height: 51px;*/
    /*    width: 65px;*/
    /*    border: 0px;*/
    /*    border-radius: 0px 10px 10px 0px;*/
    /*    border-left: 2px solid #00142D;*/
    /*    float: Right*/
    /*}*/

    #project h2 {
        font-family: 'TT-Trailers';
        -webkit-text-stroke: 1px #00142D;
        text-align: left;
        font-size: 40px;
        margin-bottom: 45px;
        min-width: 360px;
        padding-top: 20px;
        clear: both
    }

    .pugy_text img {
        width: 80%
    }

    .merge-btn {
        /*width: calc(33.3% - 22px);*/
        display: inline-block;
        position: relative;
        cursor: default;
    }

    .merge_code button {
        border-radius: 10px;
        box-shadow: 5px 5px 1px #000;
        line-height: 30px;
        margin-left: 0 !important;
    }

    #project .btn_list {
        clear: both;
        padding-top: 500px;
    }

    #project .btn_list button {
        font-family: 'Kvant-Menco-Black';
        font-size: 40px;
        -webkit-text-stroke: 1px #00142D;
        color: #fff;
        background: #FFA3FF;
        border: 2px solid #00142D;
        width: 387px;
        height: 75px;
        /*box-shadow: 10px 10px 1px #00142D;*/
        border-radius: 25px
    }
}

@media (max-width: 420px) {

    .ant-flex-align-stretch button{
        width: 250px;
    }
    #project .search_box {
        width: 100%;
        height: 55px;
        float: right;
        border: 2px solid #00142D;
        border-radius: 10px;
        display: flow;
        position: relative;
    }

    #project .search_box input {
        height: 100%;
        width: 245px;
        border: 0px;
        font-family: 'Kvant-Menco-Medium';;
        border-radius: 10px;
        padding-left: 20px;
        float: left;
        box-sizing: border-box;
    }

    #project .search_box button {
        background: #FE90FE;
        height: 55px;
        width: 65px;
        border: 0px;
        border-radius: 0px 10px 10px 0px;
        border-left: 2px solid #00142D;
        position: absolute;
        right: 0;
    }

    #project h2 {
        font-family: 'TT-Trailers';
        -webkit-text-stroke: 1px #00142D;
        text-align: left;
        font-size: 30px;
        margin-bottom: 45px;
        min-width: 320px;
        padding-top: 20px;
        clear: both
    }

    .merge-btn {
        /*width: calc(50%);*/
        margin: 0px;
        display: inline-block;
        position: relative;
        cursor: default;
        padding: 0;
    }

    #store .slide_list > li .h1_text, #store .slide_list > li:hover .h1_text {
        left: 32%;
    }


    /*.g-panel input {*/
    /*    width: 22px;*/
    /*    height: 22px;*/
    /*    top: 22px;*/
    /*    left: 22px;*/
    /*}*/


    .login button {
        background-size: cover;
        width: 46px;
        height: 45px;

    }
    .logout button {
        background-size: cover;
        width: 46px;
        height: 45px;

    }

    .w50 {
        margin-left: 0;
    }

}
.merge-number{
    width: 100%;
    max-width: 582px;
}
.merge-numb{
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    background-color: #A9FF99;
    height: 60px;
    box-sizing: border-box;
    -webkit-text-stroke: 2px black;
    font-family: 'kvant-Menco-black';
    font-size: 26px;
    font-weight: 900;
    letter-spacing: 1%;
    text-align: center;
    border-radius: 20px 0 0 20px;
    border: 2px solid black;
    padding: 0 18px;
}
.merge_code button {
    box-sizing: border-box;
    width: 100%;
    max-width: 214px;
    border-radius: 20px;
    box-shadow: 6px 6px 0 #000;
    line-height: 30px;
    margin-right: 0px;
    height: 60px;
    margin-left: 20px;
}

#project .btn_list {
    clear: both;
    padding-top: 500px;
}

/*#project .btn_list button{font-family: 'Kvant-Menco-Black';font-size:30px; -webkit-text-stroke: 1px #00142D;color:#fff;background:#FFA3FF;border:2px solid #00142D;width:80%;height:55px;box-shadow:10px 10px 1px #00142D;border-radius:15px; max-width:386px; }*/

#project .btn_list button {
    background: none;
    width: 80%;
    max-width: 386px;
    border: 0px
}

/* Customer Panel */

.customer-panel {
    max-width: 500px;
    height: auto;
    text-align: right;
    position: absolute;
    left: 50%;
    margin-left: -250px;
    top: 20px;
}

.customer-panel .bs-ul li {
    font-family: 'SBAggroB';
    margin: 10px 40px;
    font-size: 16pt;
    position: relative;
}

.customer-panel .bs-ul li:after {
    position: absolute;
    height: 3px;
    background: #fff;
    width: 0%;
    content: "";
    left: 0;
    bottom: -3px;
    transition: 0.3s;
}

.customer-panel .bs-ul li:hover::after {
    position: absolute;
    height: 3px;
    background: #fff;
    width: 100%;
    content: "";
    left: 0;
    bottom: -3px;
    transition: 0.3s;

}


.customer-panel .bs-ul .achive::after {
    position: absolute;
    height: 3px;
    background: #fff;
    width: 100%;
    content: "";
    left: 0;
    bottom: -3px;
    transition: 0.3s;

}

.customer-panel .bs-ul li:hover a {
    color: #fff
}

.customer-panel .bs-ul li a {
    color: #333
}

.customer-panel .bs-ul .achive a {
    color: #fff
}

.customer-panel .bs-ul li:hover a {
    color: #fff
}

.mobile .customer-panel .bs-ul li {
    padding: 0 20px;
}

.customer-panel .bs-ul li .icon-panel {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #555;
    margin-right: 12px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
}

.customer-panel .bs-ul li .icon-panel.phone {
    background-image: linear-gradient(#db6dfe, #9500c4);
}

.customer-panel .bs-ul li .info {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
}


/* Before After Login */


.before-login {
    display: none;
}

.before-login.active {
    display: block;
}

.before-login button {
    width: 130px;
    height: 40px;
    border-radius: 60px;
    margin-left: 6px;
}

.link {
    padding-bottom: 30px;
    overflow: hidden
}

.link li {
    font-family: Proxima Soft;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    float: left;
    width: 32%;
    margin-right: 1%;
    background: #F5F5F5;
    padding: 13px;
    border-radius: 8px;
    color: #000;
    height: 60px;
    cursor: pointer;
}

.link li:hover {
    color: #fff;
    background: #333;
}

.link li span {
    float: right;
    font-weight: 400
}

.link img {
    height: 90%;
    border-radius: 10px
}


/* After Login */

.after-login {
    display: none;
    position: relative;
}

.after-login.active {
    display: block;
}

.after-login button {
    width: 110px;
    height: 40px;
    margin-left: 5px;
}

.after-login .nav-btn {
    width: 50px;
    height: 38px;
    font-size: 14px;
    margin: 0 3px;
}

.after-login .nav-btn:hover:before {
    transform: translateY(0);
}

.after-login .active .nav-btn:before {
    transform: translateY(0);
    opacity: 1;
}

.after-login .active .nav-btn {
    color: #ffffff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
}

.after-login .message-btn .count {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #5aa532;
    color: #ffffff;
    font-size: 8px;
    position: absolute;
    right: -8px;
    top: -2px;
    text-shadow: none;
}

.after-login .mobile button {
    width: 50px;
    height: 35px;
    border-radius: 3px;
    margin-left: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 1);
    font-size: 12px;
}

.after-login .mobile .logout-btn {
    height: 35px;
}


/*quick*/

.quick {
    position: absolute;
    top: 120px;
    width: 300px;
    z-index: 9;
    left: 50%;
    display: flex;
    margin-left: -150px;
}

.quick li {
    background: #fff;
    border-radius: 5px;
    margin: 5px;
    transition: 0.3s;
    cursor: pointer;
    float: left;
    border: 1px solid #000;
    padding: 10px;
    display: flex;
    text-align: center;
    width: 140px;
    font-size: 12pt
}

.quick li:hover {
    background: #00000047;
    box-shadow: 5px 5px 5px #00000047;
    transition: 0.3s;
}

.quick li img {
    width: 50px;
    padding: 10px 10px 2px;
    transition: 0.3s;
}

.quick li a {
    color: #000;
    text-align: center
}

.quick li:hover a {
    color: #fff;
    text-align: center
}

/* Dropdown */

.drop-down {
    position: relative;
    transition: 0.3s;
}

.drop-down .toggle-btn {
    transition: 0s;
}

.drop-down .toggle-btn .icon {
    position: relative;
    top: 0;
    transition: 0.3s;
}

.drop-down.active .toggle-btn .icon {
    top: -5px;
}

.drop-down:hover .toggle-btn .icon {
    top: -5px;
}

.drop-down .toggle-btn .arrow-icon {
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    transition: 0.3s;
}

.drop-down.active .toggle-btn .arrow-icon {
    opacity: 1;
    bottom: 2px;
    transform: rotate(180deg);
    color: var(--yellow-white);
}

.drop-down:hover .toggle-btn .arrow-icon {
    opacity: 1;
    bottom: 2px;
}

.drop-down-menu {
    width: 260px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 75%;
    background-color: #2a2a2a;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 1);
    border: solid 1px rgba(255, 255, 255, 0.1);
    padding: 10px 10px;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
}

.drop-down.active .drop-down-menu {
    top: calc(100% + 10px);
    opacity: 1;
    pointer-events: auto;
}

.drop-down-menu a {
    white-space: nowrap;
    height: 40px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.03);
    padding: 0 10px;
}

.drop-down-menu a:last-child {
    border-bottom: none;
}


/* Account Info */

.after-login .account-info {
    padding: 0;
}

.after-login .account-info .row {
    background-color: rgb(0 0 0 / 42%);
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0 2px;
}

.after-login .account-info .labels {
    text-align: left;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    padding: 0 0 0 8px;
    color: #ccc;
}

.after-login .account-info .labels .icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(117 24 24 / 75%);
    background-image: linear-gradient(#f7b742, #e94e04);
    margin-right: 10px;
}

.after-login .account-info .information {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    padding: 0 0 0 10px;
}

.after-login .account-info .information .symbol {
    color: var(--yellow);
    margin-left: 3px;
}


/* Progress Bar */

.after-login .account-info .progressbar {
    background-color: transparent;
    height: 50px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    margin: 0 0 15px;
    border-radius: 0;
    box-shadow: none;
}

.after-login .account-info .progressbar .labels {
    min-width: auto;
    padding: 0;
}

.after-login .account-info .progressbar .labels .icon {
    width: 24px;
    height: 24px;
    background-image: none;
    background-color: transparent;
    border: none;
}

.progress-panel {
    width: calc(100% - 34px);
    height: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 1.0), 0 1px 2px rgba(0, 0, 0, 1.0);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.progress-panel .percent {
    width: 75%;
    height: 100%;
    border-radius: 14px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    font-family: noto-sans-bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
    position: absolute;
    left: 0;
    top: 0;
}

.progress-panel .percent:before {
    width: 200%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(to right, #fffd00, #ffdc00, #fffd00, #ffdc00, #fffd00);
    animation: percentAnim 0.5s linear infinite;
}

@keyframes percentAnim {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

.after-login .drop-down .mypage-link {
    width: 100%;
    height: 35px;
    border-radius: 3px;
    margin: 5px 0;
    border: none;
    font-size: 12px;
}

.after-login .drop-down .message-btn {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    margin: 10px 0 0;
    border: none;
    background-color: #555555;
    color: #fff;
    font-size: 12px;
    position: relative;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
}

.after-login .drop-down .message-btn .count {
    left: 0;
    top: 0;
    position: relative;
    color: #f183ff;
    border-radius: 0;
    width: auto;
    height: auto;
    background-image: none;
    background-color: transparent;
    font-size: 12px;
    margin-left: 8px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
}


/* Header Menu */

.main-menu {
    width: 100%;
    padding: 0 0px;
}

.main-menu .bs-ul {
    width: calc(100%);
    table-layout: fixed;
}

.main-menu .bs-ul li {
    padding-right: 0px;
}


.main-menu li:hover {
    opacity: 0.4 !important;
}

.main-menu .bs-ul:last-child li {
    padding-right: 0;
}

.main-menu .bs-ul li a {
    width: 100%;
    height: 60px;
    font-size: 13pt;
    font-weight: bold;
    color: #fff;
}

.main-menu .bs-ul li a:before {
    border: solid 1px rgba(255, 255, 255, 0.15);
    background-color: rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0) 51% 100%);
}

.main-menu .bs-ul li a:hover {
    color: rgba(255, 255, 255, 1.0);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0), 0 0 15px rgba(255, 255, 255, 0.5);
}

.main-menu .bs-ul li a:before,
.main-menu .bs-ul li a:after {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 0.3s;
}

.affix .main-menu .bs-ul li a:before,
.affix .main-menu .bs-ul li a:after {
    opacity: 0;
}

.main-menu .bs-ul:last-child li a:before,
.main-menu .bs-ul:last-child li a:after {
}


.main-menu .bs-ul li a:after {
    top: 4px;
}

.main-menu .bs-ul li a .icon {
    margin-right: 10px;
    transform-origin: bottom center;
    transition: 0.3s;
}

.main-menu .bs-ul li a:hover .icon {
    animation: mainMenuIcon 1s ease 1 forwards;
}

@keyframes mainMenuIcon {
    0% {
        transform: scale(1) rotate(0deg);
    }
    25% {
        transform: scale(1.1) rotate(-15deg);
    }
    50% {
        transform: scale(1.1) rotate(15deg);
    }
    75% {
        transform: scale(1.1) rotate(-10deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

.main-menu .bs-ul li a span {
    transition: 0s;
}


/* Banner Section */

.banner-section {
    width: 100%;
    margin: 34px 0 0;
}



.persnal-panel {
    padding-right: 20px;
    color: #fff
}

.persnal-panel span {
    color: #fff;
    background: #ff6600;
    padding: 5px 7px;
    border-radius: 80px;
    font-size: 9pt;
    margin-left: 5px;
}

.persnal-panel i {
    color: #f0c001
}

.nonebg .persnal-panel {
    font-size: 10pt !important;
    text-align: center
}
.wrapper{background:url("/public/image/img/bg/back.jpg") center top;min-height:995px}
.intig {cursor:pointer;border:1px solid #555}
.intig:hover {filter: brightness(1.3);border:1px solid #eee;box-shadow:1px 1px 5px #ddd}
.walbtn {
  height: 53px !important;
  border-radius: 40px;
  border: 2px solid #333;
  background: #fff;
  width: 197px !important;
  font-family: NanumSquareRound;
  font-size: 16px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  color:#333;
  transition: 0.3s;
}
.walbtn:hover {
  height: 53px !important;
  border-radius: 40px;
  border: 2px solid #d02b69;
  background: #fff;
  width: 197px !important;
  font-family: NanumSquareRound;
  line-height: 32px;
  text-align: center;
  color:#d02b69;
  transition: 0.3s;
}
.login {
    position:absolute;
    right:50px;
    top: 40px;
    margin-right: 50px;
}

/*.login img{*/
/*   width:58px*/
/*}*/
.login button{
  background:none; border:0px;
  width: 76px;
  height: 75px;
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
}
.login button:first-of-type {
    /*  background: url(/public/image/loginBtn.png);*/
    /*}*/
}
.login button:nth-of-type(2){
  /*background: url(/public/image/btn_mypage_1.png);*/
}


.banner-section .banner-panel {
    height: 300px;
    border-radius: 5px;
    background-image: linear-gradient(to right, rgb(0 0 0 / 2%), rgb(175 31 97 / 80%), rgb(246 93 0 / 40%), rgb(0 0 0 / 9%));
}

.banner-background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.logout button.logoutBtn{
  background: url(/public/image/logoutBtn.png);
    margin-left: 10px;

}

.banner-background img {
    position: absolute;
    bottom: 0;
}

.banner-background div {
    position: absolute;
    bottom: 0;
}
.logout button{border:0px;
  width: 76px;
  height: 75px;
}

.banner-background .palace {
    width: 49.32%;
    left: 5.5%;
    animation: palaceAnim 12s ease infinite;
}

@keyframes palaceAnim {
    0% {
        opacity: 0;
        transform: translateX(-10%);
    }
    15% {
        opacity: 1;
        transform: translateX(0);
    }
    95% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(0);
    }
}

.banner-background .piggy {
    width: 25.76%;
    left: 0;
    animation: piggyAnim 12s ease infinite;
}

@keyframes piggyAnim {
    0% {
        opacity: 0;
        transform: translateX(25%);
    }
    15% {
        opacity: 1;
        transform: translateX(0);
    }
    95% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(0);
    }
}

.banner-background .coins {
    width: 28.43%;
    left: -2%;
    animation: coinsAnim 12s ease infinite;
}

@keyframes coinsAnim {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }
    20% {
        opacity: 0;
        transform: scale(0.75);
    }
    23% {
        opacity: 1;
        transform: scale(1.05);
    }
    30% {
        opacity: 1;
        transform: scale(1);
    }
    95% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.banner-background .pig-glow {
    width: 11.79%;
    left: 6.75%;
    bottom: auto;
    top: 0;
    animation: pigGlowAnim 12s ease infinite;
}

article.bpart img {
/*transition: opacity 0.3s ease; !* 부드러운 트랜지션 효과 *!*/
/*width: 100%; !* 버튼에 맞게 이미지 크기 조정 *!*/
/*height: auto; !* 비율 유지 *!*/
}

/*article.bpart button:hover img {*/
/*opacity: 0; !* 호버 시 이미지 투명하게 *!*/
/*}*/

/*article.bpart button:hover::after {*/
/*content: ''; !* 호버 시 추가 콘텐츠 생성 *!*/
/*background-image: url('/public/image/new_btn_ico02.png'); !* 호버 이미지 *!*/
/*background-size: contain; !* 이미지 크기 조정 *!*/
/*background-repeat: no-repeat; !* 반복하지 않음 *!*/
/*position: absolute; !* 절대 위치 설정 *!*/
/*top: 0; !* 상단 맞춤 *!*/
/*left: 0; !* 좌측 맞춤 *!*/
/*width: 100%; !* 전체 폭 *!*/
/*height: 100%; !* 전체 높이 *!*/
/*opacity: 1; !* 완전 불투명 *!*/
/*transition: opacity 0.5s ease; !* 부드러운 트랜지션 효과 *!*/
/*max-width: 44px;*/
/*padding: 10px;*/
/*}*/

/*article.bpart button:hover::after {*/
/*opacity: 1; !* 호버 시 완전 불투명으로 설정 *!*/
/*}*/

@keyframes pigGlowAnim {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    23% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}





.banner-background .pig-glow img {
    animation: pigGlowImgAnim 0.5s ease infinite;
}

.bpart {position:absolute;bottom:40px;width: 100%; }
/*  .bpart button{*/
/*	  background: none; border: 0px;*/
/*	  cursor: pointer; padding: 0;*/
/*  }*/



.bpart img{
  width: 100%;
}

.bpart button:not(:first-of-type){
	margin-left: 86px;
}


/*.bpart button{background:none;border:0px; position: relative; cursor: pointer; padding: 20px 0 0}*/
.bpart button:not(:first-of-type){
  margin-left: 4.47%;
}

@keyframes pigGlowImgAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.banner-background .seven {
    width: 15.55%;
    left: 20.5%;
    animation: sevenAnim 12s ease infinite;
}

@keyframes sevenAnim {
    0% {
        opacity: 0;
        transform: translateY(-25%);
    }
    8% {
        opacity: 0;
        transform: translateY(-25%);
    }
    15% {
        opacity: 1;
        transform: translateY(5%);
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.banner-background .fireworks {
    width: 7.13%;
    left: 40.5%;
    bottom: auto;
    top: 6%;
    left: 35%;
    animation: fireworksAnim 12s ease infinite;
}

@keyframes fireworksAnim {
    0% {
        opacity: 0;
        transform: scale(0.15) translateY(600%);
    }
    10% {
        opacity: 0;
        transform: scale(0.15) translateY(600%);
    }
    20% {
        opacity: 1;
        transform: scale(0.15) translateY(0);
    }
    23% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    95% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    100% {
        opacity: 0;
        transform: scale(1) translateY(0);
    }
}

.banner-background .loy-kratong {
    width: 38.98%;
    left: 12.75%;
    transform-origin: bottom left;
    animation: loyAnim 12s ease infinite;
}

@keyframes loyAnim {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }
    5% {
        opacity: 0;
        transform: scale(0.75);
    }
    15% {
        opacity: 1;
        transform: scale(1);
    }
    95% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.banner-background .spark {
    width: 18.46%;
    right: -6%;
    bottom: 26.5%;
    animation: firecrackersSparkAnim 0.2s ease infinite;
}

@keyframes firecrackersSparkAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}


/* Banner Carousel */

.banner-carousel {
    width: 60%;
    height: 100%;
    margin-left: auto;
}

.banner-carousel .text-panel {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 1.0);
    opacity: 0;
    animation: bannerCarousel 5s ease 1 forwards;
}

@keyframes bannerCarousel {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    15% {
        opacity: 1;
        transform: translateY(0);
    }
    85% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.banner-carousel .text-panel h1 {
    font-family: 'TT-Trailers';
    font-size: 35px;
}

.banner-carousel .text-panel h2 {
    font-size: 26px;
    font-family: 'TT-Trailers';
    font-weight: 400
}

h5 {
    font-family: 'TT-Trailers';
    font-size: 25px;
    text-align: left;
    color: #000
}

.banner-carousel .more-btn {
    width: 200px;
    height: 50px;
    background: #FF512F; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #F09819, #FF512F); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #F09819, #FF512F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: none;
    border-radius: 30px;
    color: #ffffff;
    font-size: 18px;
    font-family: 'TT-Trailers';
    margin: 30px 0 0;
    line-height: 50px;
}

.banner-carousel .more-btn:hover {
    color: #ffffff;
    background: #FF512F; /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #F09819, #FF512F); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #F09819, #FF512F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


/* Carousel Nav */

.carousel-nav {
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.carousel-indicators {
    margin: 0;
    bottom: 0;
    position: relative;
    display: block;
}

.carousel-indicators li {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 4px 0;
    background-color: rgba(255, 255, 255, 0);
    border: solid 1px rgba(255, 255, 255, 0);
    opacity: 1;
    transition: 0.3s;
    display: block;
}

.carousel-indicators li.active {
    border-color: rgba(255, 255, 255, 0.15);
}

.carousel-indicators li:before {
    width: 8px;
    height: 8px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: 0.3s;
}

.carousel-indicators li:hover:before {
    background-color: rgba(255, 255, 255, 1);
}

.carousel-indicators li.active:before {
    background-color: var(--yellow);
}

.carousel-nav .carousel-pn {
    width: 18px;
    height: 25px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    border: solid 1px rgba(255, 255, 255, 0.25);
    margin: 10px 0;
}

.carousel-nav .carousel-pn:hover {
    color: var(--yellow);
    border-color: rgba(255, 255, 255, 0.5);
}

.carousel-nav .carousel-pn:first-child {
    border-radius: 9px 9px 0 0;
}

.carousel-nav .carousel-pn:last-child {
    border-radius: 0 0 9px 9px;
}


/* Search Section */

.search-section {
    margin: 40px 0 0;
}


.search-section .title-panel .icon {
    width: 38px;
    position: relative;
    top: 3px;
    margin-right: 15px;
}

.search-section .title-panel .title {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(#ffffff, #cccccc);
    background-size: 100% 100%;
    position: relative;
    color: #ffffff;
    font-size: 25px;
    white-space: nowrap;
    margin: 0;
    font-family: 'TT-Trailers';
}


.search-panel {
    width: 100%;
    max-width: 400px;
    height: 44px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0 0 0 auto;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.05);
    overflow: hidden;
    position: relative;
}

.search-panel input {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: 8px;
    padding: 0 30px;
    color: #ffffff;
}

.search-panel input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.25);
}

.search-panel .search-btn {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    overflow: hidden;
}


/* merch Section */


.merch-panel {
    width: calc(100% + 22px);
    margin: 0 -11px;
}

.merch-btn {
    width: calc(25% - 22px);
    margin: 0 11px 80px;
    display: inline-block;
    position: relative;
    cursor: default;
}

.merch-btn .g-panel {
    width: 100%;
    box-shadow: 5px 5px 3px #00000052;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #000;
}

.merch-btn .g-panel:before,
.merch-btn .g-panel:after {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    background-size: 100% 100%;
    /*background-image: url(/public/image/img/bg/sb-border.png);*/
    transition: 0.3s;
    z-index: 1;
}

.merch-btn .g-panel:after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    /*background-image: url(/public/image/img/bg/sb-border-on.png);*/
    left: -1px;
    top: -1px;
    opacity: 0;
}

.merch-btn:hover .g-panel:after {
    opacity: 1;
}

.merch-btn .g-panel .g-bg {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 1.0));
}

.merch-btn .g-panel .g-glow {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transition: 0.3s;
    opacity: 0.6;
}

.merch-btn:hover .g-panel .g-glow {
    opacity: 1;
}

.merch-btn .g-panel .g-items {
    width: 115.06%;
    position: absolute;
    left: -7%;
    top: -12%;
    z-index: 2;
}

.merch-btn:hover .g-panel .g-items {
    animation: gItemsAnim 1s ease 1 forwards;
}

@keyframes gItemsAnim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.merch-btn .g-panel .g-img {
    width: calc(100%);
    bottom: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    transition: 0.3s;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    height: 300px;
}

.merch-btn:hover .g-panel .g-img {
    filter: brightness(50%);
}

.merch-btn .play-btn {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    margin: auto;
    z-index: 3;
    opacity: 0;
    transform: scale(0.5);
    border: 0px;
    background: none;
}

.merch-btn .play-btn:before {
    display: none;
}

.merch-btn:hover .play-btn {
    transform: scale(0.8);
    opacity: 1;
}

.merch-btn .g-footer {
    width: calc(100% - 2px);
    height: 57px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: 0 auto;
    padding: 0 0 5px;
    background-size: cover;
    background-position: center bottom;
    /* background-image: url(/public/image/img/bg/sb-footer.png);*/
    z-index: 3;
}

.merch-btn .g-footer:before {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 55px;
    background-image: linear-gradient(to right, transparent, var(--orange), transparent);
}

.merch-btn .g-footer:after {
    width: 100%;
    height: 77px;
    position: absolute;
    left: 0;
    top: 17px;
    background-image: url(/public/image/img/bg/sb-glare.png);
    background-position: center;
    background-repeat: no-repeat;
    animation: sbGlareAnim 0.8s ease infinite;
}

@keyframes sbGlareAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

.merch-btn .g-name {
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    width: 100%;
    padding: 18px 10px 10px;
    line-height: 25px;
    background-size: cover;
    left: 0;
    top: 123px;
    z-index: 1;
    background: #fff;
    border-radius: 10px;
    color: #333
}

.merch-btn:hover .g-name {
    font-weight: bold;
}


/* Notice Board */

.notice-board {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.05);
    position: relative;
    margin: 10px 0 0;
}

.notice-board .notice-link {
    width: 100px;
    height: 36px;
    position: absolute;
    left: 4px;
    border-radius: 18px;
}

.marquee {
    width: calc(100% - 10px);
    overflow: hidden;
    padding: 2px 0;
    margin-left: auto;
}

.marquee ul {
    display: flex;
}

.marquee ul li {
    padding: 0 40px;
    color: #fff;
    font-size: 12px;
    position: relative;
    cursor: pointer;
}

.marquee ul li:after {
    content: '';
    width: 1px;
    height: 80%;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.marquee ul li:last-child:after {
    display: none;
}

.marquee ul li .title {
    padding-right: 30px;
}

.marquee ul li a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    overflow: hidden;
}

.marquee ul li a:hover {
    color: var(--yellow);
}

.marquee ul li .date {
    color: rgba(255, 255, 255, 0.5);
}


/* Footer Section */

.footer-section {
    margin: 0px 0 0;
    background: #141414;
    padding: 20px;
    border-top: 1px solid #333;
}

.footer-section:before {
    width: 100%;
    height: 262px;
    left: 0;
    bottom: 0;
    /*background-image: url(/public/image/img/bg/footer-bg.png);*/
    background-repeat: no-repeat;
    background-position: bottom center;
}

.footer-section .company-logo img {
    margin: 7px 10px;
    height: 18px;
}

.footer-section .copyright-panel {
    color: rgba(255, 255, 255, 0.2);
    font-size: 10px;
    letter-spacing: 1px;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    padding: 10px 0;
    margin: 30px 0 0;
    background: #000;
    border-radius: 30px;
}

/*=========================================================== M O D A L ===============================================================*/


.modal-backdrop {
    background-color: #000000;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal {
    padding: 0 !important;
    overflow-y: auto;
}

.modal::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

.modal::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.modal *::-webkit-scrollbar {
    width: 10px;
    background: #000000;
    border-radius: 5px;
}

.modal *::-webkit-scrollbar-thumb {
    background: linear-gradient(#888888, #666666);
    border: solid 2px #000000;
    border-radius: 5px;
}

.modal-dialog {
    max-width: 970px;
    max-height: 828px;
    padding: 0 !important;
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 2 * 20px); /* 예시: $modal-dialog-margin 값을 20px로 설정한 경우 */
}
.gameListModal .modal-dialog {
    max-width: 1400px;
}

.loginModal .modal-dialog {
    max-width: 560px;
}

.p30 {
    padding: 30px
}

.modal-content {
    /*background-color: rgba(30, 30, 30, 0.8);*/
    border-radius: 15px;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: stretch;
    flex-direction: row;
    overflow: hidden;
    border: 0px !important;
}

.modal-content img {
    display: block
}

/* Modal Close Btn */

.modal-close-btn {
    width: 48px;
    height: 48px;
    background-color: #000;
    border: none;
    z-index: 9;
    position: absolute;
    border-radius: 0%;
    right: 48px;
    top: 70px;
}

.modal-close-btn:before,
.modal-close-btn:after {
    width: 2px;
    height: 60%;
    background-color: #fff;
    left: 5px;
    right: 5px;
    top: -2px;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
    transition: 0.5s;
}

.modal-close-btn:after {
    transform: rotate(-45deg);
}


.modal-close-btn:hover:before {
    transform: rotate(135deg);
    background-color: var(--yellow);
}

.modal-close-btn:hover:after {
    transform: rotate(-135deg);
    background-color: var(--yellow);
}

.modal-close-btn2 {
    width: 64px;
    height: 64px;
    border: none;
    /*z-index: 9;*/
    position: absolute;
    border-radius: 0%;
    right: 0;
    background: #fff;
    top: 0;
}

/*.modal-close-btn2 img{width: 18px; margin: 0 auto;}*/
.modal-close-btn2 img {
    width: 25px;
    margin: 0 auto;
}

.modal .mob-menu-btn {
    position: absolute;
    left: 10px;
    top: 6px;
    display: none;
}

/* Modal Slider*/
.areaModal {
    position: relative;
}

/* 슬라이드 컨테이너 */
.slide-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    position: relative;
}

/* 각 슬라이드 */
.slide {
    min-width: 100%;
    display: none;
    transition: transform 0.5s ease-in-out;
}

/* 슬라이드가 활성화되었을 때 보이게 처리 */
.slide.active {
    display: block;
}

/* 내비게이션 버튼 */
.slide-navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.slide-navigation button {
    background: transparent;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
}


.prev-slide {
    left: -110px;
    position: absolute;
}

.next-slide {
    position: absolute;
    right: 40px;
}

/* Modal Sidebar */

.modal-sidebar {
    width: 200px;
    /*width: 300px;*/
    background-color: #fff;
    border-right: solid 1px #000000;
}

.modal-sidebar:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /*background-image: url(/public/image/img/bg/jackpot-bg.png);*/
    background-repeat: repeat-x;
    transform: rotateX(180deg);
    background-position: bottom center;
}

.modal-sidebar .header {
    width: 100%;
    padding: 15px 0;
    margin: 0 0 10px;
}

.modal-sidebar .header .icon {
    width: 100px;
}

.modal-sidebar .header .title {
    width: 100%;
    display: inline-block;
    margin: 10px 0 0;
    font-size: 18px;
}


/* Modal Menu */

.modal-menu {
    width: 100%;
    display: block;
    background-color: rgba(255, 255, 255, 0.03);
}

.modal-menu li {
    width: 100%;
    display: block;
}

.modal-menu li a {
    width: 100%;
    height: 54px;
    color: rgba(255, 255, 255, 0.5);
}

.modal-menu li a:hover {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
}

.modal-menu li a.active {
    color: #ffffff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
    background-color: rgba(255, 255, 255, 0.05);
}

.modal-menu li a .center {
    min-width: 140px;
}

.modal-menu li a span {
    transition: 0s;
}

.modal-menu li a .icon-panel {
    width: 40px;
    margin-right: 12px;
    font-size: 18px;
}

.modal-menu li a.active .icon-panel {
    color: var(--yellow);
}

.modal-menu li a .icon-panel img {
    max-width: 26px;
    max-height: 26px;
    transform-origin: bottom right;
}

.modal-menu li a:hover .icon-panel img {
    animation: mainMenuIcon 1s ease 1 forwards;
}

.modal-menu li a.active:hover .icon-panel img {
    animation: none;
}


/* Modal Panel */

.modal-panel {
    /*width: calc(100% - 300px);*/
    width: calc(100% - 200px);
}


/* Modal Title */

.modal-title {
    height: 50px;
    width: 100%;
    max-width: 870px;
    background-color: #0a0a0a;
    border-radius: 15px;
    margin: 20px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.08), inset 0 2px 10px rgba(0, 0, 0, 1.0);
}

.modal-title .icon {
    margin-right: 12px;
}

.modal-title i {
    color: var(--yellow);
    font-size: 18px;
    margin-right: 12px;
}

.modal-title .title {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(#ffffff, #ffffff, #888888);
    background-size: 100% 100%;
    position: relative;
    color: #ffffff;
    font-size: 18px;
    white-space: nowrap;
    margin: 0;
    font-weight: bold;
    font-family: 'TT-Trailers';

}

.modal-title .title:after {
    content: attr(data-text);
    display: inline-block;
    background: 0 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 1.0), 0 0 15px rgba(255, 255, 255, 0.35);
    z-index: -1;
}


/* Modal Body */

.modal-body {
    padding: 0px;
    max-width: 100%;
    width: 100%;
}


.modal-footer {
    height: 266px;
    max-width: 100%;
    width: 100%;
    border-top: 3px solid #00142D;
    justify-content: Center;
    border-radius: 0 0 30px 30px;
}

.modal-footer button {
    background: none;
    border: 0px
}

.modal-footer p {
    color: #00142d;
    font-family: kvant-Menco-black;
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    padding: 30px 0px;
}


/* Modal Info */

.modal-info {
    width: 100%;
    height: 60px;
    /*height: 40px;*/
    border-radius: 30px;
    /*border-radius: 20px;*/
    background: #f12711; /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #e82c18, #f12711); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #e82c18, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin: 0 0 30px;
    position: relative;
}

.modal-info .icon-panel {
    width: 32px;
    height: 32px;
    left: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    position: absolute;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.modal-info .text-panel {
    width: calc(100% - 34px);
    font-size: 12px;
    padding: 0 15px;
    margin-left: auto;
    color: rgba(255, 255, 255, 0.5);
}

.modal-info .text-panel a {
    color: #ffffff;
}

.modal-info .text-panel a:hover {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0), 0 0 15px rgba(255, 255, 255, 0.4);
}

.modal-info .text-panel a i {
    color: #fa994b;
    font-size: 14px;
    margin-left: 5px;
    transition: 0.3s;
}

.modal-info .text-panel a:hover i {
    color: var(--yellow);
}


/* Form Container */

.form-container {
    width: 100%;
    max-width: 1200px;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.form-container .form-group {
    width: 100%;
    display: inline-block;
    margin: 0 0 15px;
}

.form-container .form-group:last-child {
    margin: 0 0;
}

.form-container .labels {
    text-align: left;
    color: #fff;
    font-size: 14px;
    /* text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0); */
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 0 5px;
}

.form-container .infos {
    width: 100%;
    display: flex;
    align-items: center;
}

.form-container .form-group .input-container {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.form-container .w-btn .input-container {
    width: calc(100% - 125px);
}

.form-container .form-group input {
    width: 100%;
    height: 45px;
    float: left;
    color: #333333;
    background-color: #fff;
    border: solid 1px #000;
    border-radius: 5px;
    transition: 0.3s;
    padding: 0 15px;
}

.customerModal .form-container .form-group input {
    height: 70px;
    font-size: 18px;
}

.form-container .w-icon input {
    padding: 0 0 0 50px;
}

.form-container .form-group input:read-only {
    color: #777;
    font-family: noto-sans-bold;
    /* text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0); */
    border: 1px solid #000;
    margin-bottom: 10px;
    font-size: 13pt;
}

.form-container .form-group input::-webkit-input-placeholder {
    color: #555;
}

.form-container .form-group .input-container .icon-panel {
    width: 50px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: rgba(255, 255, 255, 0.5);
}

.form-container .form-group .select-input {
    width: 100%;
    height: 45px;
    float: left;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    border: solid 1px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.form-container .form-group .select-input select {
    width: 100%;
    height: 100%;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    -webkit-appearance: none;
    padding: 0 15px;
    cursor: pointer;
}

.form-container .form-group select option {
    background-color: #1f0a0a;
    color: #fff;
}

.form-container .form-group .select-input i {
    font-size: 18px;
    position: absolute;
    right: 15px;
}

.form-container .form-group textarea {
    width: 100%;
    height: 200px;
    float: left;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    border: solid 1px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    transition: 0.3s;
    padding: 20px 18px;
    resize: none;
}

.form-container .form-group textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.form-container .form-group .form-btn {
    width: 120px;
    height: 44px;
    border: none;
    float: right;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 0.3s;
    margin: 0 0 0 5px;
    border-radius: 10px;
}


/* Form Btn Group */

.form-container .form-group .infos .btn-grp {
    width: 100%;
    float: left;
}

.form-container .form-group .infos .btn-grp button {
    width: 16.66%;
    width: calc(16.66% - 4px);
    height: 38px;
    float: left;
    margin: 0 2px;
    background-color: #666666;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    font-size: 12px;
    transition: 0.3s;
    padding: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.15);
}

.form-container .form-group .infos .btn-grp button:hover {
    background-color: #888888;
}

.form-container .form-group .infos .btn-grp button:first-child {
    margin-left: 0;
    width: calc(16.66% - 2px);
}

.form-container .form-group .infos .btn-grp button:last-child {
    width: calc(16.66% - 2px);
    margin-right: 0;
    background-color: #c94a2c;
}

.form-container .form-group .infos .btn-grp button:last-child:hover {
    background-color: #ff5b34;
}


/* Form Footer */

.form-footer {
    width: 100%;
    margin: 50px 0 0;
    flex-wrap: nowrap;
}

.form-footer button {
    width: 160px;
    height: 48px;
    margin: 0 5px;
}

.form-footer .btn-transparent {
    box-shadow: none;
}


/* Modal Banner */

.modal-banner {
    height: 100px;
    margin: 0 0 30px;
    border-radius: 5px;
    background-image: linear-gradient(to right, rgba(255, 244, 0, 0.8), rgba(251, 125, 0, 0.8), rgba(246, 69, 0, 0.4), rgba(246, 69, 0, 0.4));
    position: relative;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
}

.modal-banner:before,
.modal-banner:after {
    width: 100%;
    height: 121px;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.modal-banner:before {
    /*background-image: url(/public/image/img/bg/modal-banner-left.png);*/
    background-position: left bottom;
}

.modal-banner:after {
    /* background-image: url(/public/image/img/bg/modal-banner-right.png);*/
    background-position: right bottom;
}

.modal-banner .mb-text {
    font-size: 18px;
}


/* Customer Modal */

.customerModal .customer-panel .bs-ul li {
    padding: 0 10px;
    font-size: 20px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
}

.customerModal .customer-panel .bs-ul li .icon-panel {
    font-size: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 1.0);
}


/* Pagination */

.pagination {
    margin: -15px 0 0;
}

.pagination li {
    float: left;
}

.pagination > li > a {
    width: 28px;
    height: 28px;
    color: #ffffff;
    text-decoration: none;
    background-color: #444444;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    transition: 0.3s;
    overflow: hidden;
}

.pagination .turn-pg a {
    font-size: 12px;
    margin: 0 13px;
    background-color: #606060;
}

.pagination > li > a:hover {
    background-color: #808080;
}

.pagination .active {
    background-color: #df4c25;
    background-image: linear-gradient(#fa5d16, #c42706);
}

.pagination .active:hover {
    background-color: #df4c25;
}


/* BS Table */

.bs-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    max-width: 1200px;
    margin: 0 auto;
}

.bs-table.with-depth {
    border-spacing: 0 2px;
}

.bs-table thead th {
    height: 50px;
    color: #fff;
    font-size: 11pt;
    text-align: center;
    background: #f12711;
    background: -webkit-linear-gradient(to top, #db3826, #f12711);
    background: linear-gradient(to top, #477dfd, #477dfd);
    font-weight: 400;
}

.bs-table thead th:first-child {
    border-radius: 5px 0 0 5px;
}

.bs-table thead th:last-child {
    border-radius: 0 5px 5px 0;
}

.with-depth thead th {
    border-radius: 5px !important;
}

.bs-table tr {
    cursor: pointer;
}

.bs-table tr td {
    height: 50px;
    color: #333;
    font-size: 11pt;
    text-align: center;
    padding: 1px;
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.05);
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    border-bottom: solid 1px #ddd;
}

.bs-table tr td:first-child {
    border-radius: 5px 0 0 5px;
    border-left: solid 1px rgba(255, 255, 255, 0.1);
}

.bs-table tr td:last-child {
    border-radius: 0 5px 5px 0;
    border-right: solid 1px rgba(255, 255, 255, 0.1);
}

.bs-table tr:hover td {
    background-color: rgba(255, 255, 255, 0.1);
}

.bs-table tr td a {
    color: #fff;
    font-size: 12px;
    transition: 0.3s;
    display: inline-block;
    vertical-align: middle;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.bs-table tr td a:hover {
    color: var(--yellow);
}

.bs-table tr.active .title-td {
    color: var(--yellow);
}

.bs-table tr.active td a {
    color: var(--yellow);
}

.bs-table tr .count-td {
    width: 60px;
}

.bs-table tr .count-tag {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    padding: 1px 0;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.75);
    font-family: noto-sans-bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
    background-image: linear-gradient(#fffd00, #db8812);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 1), 0 0 5px rgba(255, 255, 255, 0.15);
}

.bs-table tr .title-td {
    text-align: left;
    padding-left: 15px;
    max-width: 590px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bs-table tr .date-td {
    width: 20%;
    color: #cccccc;
}

.bs-table tr .nav-td {
    width: 40px;
}

.bs-table tr td .delete-btn {
    background-color: transparent;
    border: none;
    color: #cccccc;
    font-size: 14px;
    transition: 0.3s;
}

.bs-table tr td .delete-btn:hover {
    color: #ff9200;
}

.bs-table tr td .nav-btn {
    background-color: transparent;
    border: none;
    position: relative;
}

.bs-table tr td .nav-btn i {
    position: relative;
    color: #fff;
    font-size: 10px;
    transition: 0.3s;
    transform: rotate(0deg);
}

.bs-table tr td .nav-btn:hover i {
    color: #ffca5f;
}

.bs-table tr.active td .nav-btn i {
    transform: rotate(180deg);
    top: 5px;
}

.bs-table tr td .plus-btn {
    width: 16px;
    height: 16px;
    background-color: var(--yellow);
    border: none;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    padding: 0 0 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.bs-table tr td .plus-btn:hover {
    background-color: #ff9200;
}

.bs-table tr td .plus-btn:before,
.bs-table tr td .plus-btn:after {
    content: '';
    width: 2px;
    height: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    transition: 0.3s;
}

.bs-table tr td .plus-btn:after {
    transform: rotate(90deg);
}

.bs-table tr.active td .plus-btn:before {
    height: 0;
}

.bs-table tr td .plus-btn:hover:before,
.bs-table tr td .plus-btn:hover:after {
    background-color: #ffffff;
}


/* With Depth */

.with-depth tr:nth-last-child(2) td:first-child {
    border-radius: 0 0 0 10px;
}

.with-depth tr:nth-last-child(2) td:last-child {
    border-radius: 0 0 10px 0;
}

.with-depth .message-content {
    display: none;
    height: 200px;
    margin: 2px 0 5px;
}

.with-depth tr.depth-click {
    cursor: pointer;
}

.with-depth tr.dropdown {
    background-color: transparent;
}

.with-depth tr.dropdown td {
    padding: 0;
    border: none;
    height: auto;
    box-shadow: none;
}

.with-depth tr.dropdown td {
    background-color: transparent;
    border: none;
}

.with-depth tr.dropdown:hover td {
    background-color: transparent;
    border: none;
}


/* Message Content */

.message-content {
    width: 100%;
    height: 400px;
    background-color: #111111;
    margin: 5px 0 0;
    padding: 10px;
    border-radius: 5px;
}

.message-content .inner-container {
    width: 100%;
    height: 100%;
    float: left;
    color: #fff;
    /*white-space: pre-wrap;*/
    text-align: left;
    padding: 5px 10px;
}


/* Mypage Modal */

.modal-tab {
    width: 100%;
    display: none;
    margin: 0 auto;
    animation: modalTabAnim 0.5s ease 1 forwards;
}

@keyframes modalTabAnim {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-tab.active {
    display: block;
}


/* Level Information */

.bs-table td .level-icon {
    margin-right: 8px;
}

.bs-table td .level-txt {
    display: inline-block;
    width: 55px;
    text-align: left;
}

.level-information {
    width: 100%;
    height: 80px;
    position: relative;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 0 15px;
    background: #141414;
}

.level-information .bs-ul {
    width: calc(100% - 310px);
}

.level-information .bs-ul li {
    width: calc(50% - 10px);
    height: 40px;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
}

.level-information .bs-ul li .labels {
    margin-right: 10px;
}

.level-information .point-form {
    width: 310px;
    height: 100%;
    position: relative;
}

.level-information .point-form input {
    width: calc(100% - 115px);
    height: 38px;
    border-radius: 3px;
    padding: 0 5px;
    color: #fff;
    font-size: 12px;
    background-color: #555;
    border: none;
    margin-right: auto;
}

.level-information .point-form input::-webkit-input-placeholder {
    color: #fff;
}

.level-information .points-btn {
    width: 110px;
    height: 38px;
    font-size: 12px;
    border: none;
    transition: 0.3s;
    white-space: nowrap;
}


/* Modal List */

.modal-list {
    margin: 0 0 30px;
    flex-wrap: nowrap;
    border-radius: 5px;
    overflow: hidden;
}

.modal-list .bs-ul li {
    width: 100%;
}

.modal-list a {
    width: 100%;
    height: 50px;
    border: none;
    background-color: #fafafa;
    color: #333;
    border-bottom: solid 2px #333333;
    font-family: 'TT-Trailers';
    text-align: center;
}

.modal-list a:hover {
    color: #477dfd
}

.modal-list a.active {
    color: #333333;
    border-color: var(--yellow);
}

.modal-list a i {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 8px;
    transition: 0.3s;
}

.modal-list a:hover i {
    color: var(--yellow);
}

.modal-list a.active i {
    color: var(--yellow);
}


/* Gamelist Modal */

.gameListModal .modal-sidebar {
    width: 250px;
}

.gameListModal .modal-list {
    display: none !important;
}

.gameListModal .modal-panel {
    width: calc(100% - 250px);
}

.gameListModal .search-panel {
    max-width: 600px;
    margin: 0 auto;
}

.game-list-container {
    width: 100%;
    margin: 0 auto;
}

.game-btn {
    width: calc(16.66% - 14px);
    display: inline-block;
    margin: 0 7px 14px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: #333333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    animation: scBtnAnim 1s ease 1 backwards;
    padding: 1px;
}

.game-btn:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5), 0 0 15px rgba(255, 221, 118, 0.25);
}

.game-btn:before {
    width: 100%;
    height: 100%;
    transition: 0.3s;
    background: linear-gradient(#409429, #50be33);
    top: 0;
    left: 0;
    opacity: 0;
}

.game-btn:hover:before {
    opacity: 1;
}

.game-btn .g-panel {
    width: 100%;
    position: relative;
    background-color: #000000;
    border-radius: 10px;
    overflow: hidden;
}

.game-btn .g-panel:before {
    width: 100%;
    height: 40%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    z-index: 1;
}

.game-btn .g-panel .g-img {
    transition: 0.5s;
}

.game-btn:hover .g-panel .g-img {
    opacity: 0.5;
    transform: scale(1.1);
}

.game-btn .g-panel .g-footer {
    width: 100%;
    height: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    position: absolute;
    padding: 0 4px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75));
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 1);
    font-family: noto-sans-bold;
    z-index: 2;
}

.game-btn:hover .g-panel .g-footer {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0);
    box-shadow: none;
}

.game-btn .g-panel .g-footer .name-text {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    color: #ffffff;
    font-size: 12px;
    max-width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 1), 0 0 15px rgba(255, 255, 255, 0.5);
}

.game-btn .g-panel .play-btn {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: 50%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.75);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    font-size: 22px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 1.0);
    opacity: 0;
    transition: 0.3s;
    padding: 0 0 0 4px;
    color: #fff
}

.game-btn .g-panel .play-btn:hover {
    padding: 0;
}

.game-btn .g-panel .play-btn:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    transition: 0.3s;
    background: linear-gradient(#141414, #333);
}

.game-btn:hover .g-panel .play-btn {
    animation: gamePlayAnim 0.8s ease 1 forwards;
}

@keyframes gamePlayAnim {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    40% {
        opacity: 1;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.game-btn .g-panel .play-btn i {
    transition: 0.3s;
}


.game-btn .loading {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
}

.modal-list a .count-tag {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fb6331;
    background-image: linear-gradient(#fb6331, #fb2c2c);
    color: #ffffff;
    font-size: 10px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
    padding: 0 0 2px;
    margin-left: 5px;
}

/* Popular Games */

.popular-section {
    margin: 30px 0 0;
}

.popular-section .header {
    height: 45px;
    margin: 0 0 -1px;
}

.popular-section .header .title-panel {
    width: 100%;
    max-width: 300px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    color: rgba(0, 0, 0, 0.75);
    font-size: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 1.0));
}

.popular-section .header .title-panel:before,
.popular-section .header .title-panel:after {
    width: 75%;
    height: 100%;
    top: 0;
    background-image: linear-gradient(#f93f00, #fe8300);
    z-index: 2;
}

.popular-section .header .title-panel:before {
    left: 0;
    transform: skew(-25deg);
    border-radius: 8px 0 0;
}

.popular-section .header .title-panel:after {
    right: 0;
    transform: skew(25deg);
    border-radius: 0 8px 0;
}

.popular-section .header .title-panel .title {
    z-index: 3;
    position: relative;
}

.popular-games {
    width: calc(100% + 16px);
    margin: 0 -8px;
    padding: 25px 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0));
}

.popular-games:before,
.popular-games:after {
    width: 100%;
    height: 1px;
    left: 0;
    background-image: linear-gradient(to right, rgba(254, 209, 48, 0), rgba(254, 209, 48, 0.5), rgba(254, 209, 48, 0));
    box-shadow: 0 0 5px rgba(0, 0, 0, 1.0);
}

.popular-games:before {
    top: 0;
}

.popular-games:after {
    bottom: 0;
}

.popular-games .owl-carousel .owl-item {
    padding: 0 8px;
}

.owl-nav-btn {
    width: 60px;
    height: 30px;
    position: absolute;
    bottom: 0;
    border: none;
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
    padding-bottom: 2px;
}

.owl-nav-btn:before,
.owl-nav-btn:after {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#fa4f00, #db502a);
    top: 0;
    left: 0;
}

.owl-nav-btn.left:before,
.owl-nav-btn.left:after {
    transform: skew(-25deg);
    border-radius: 10px 0 0 0;
}

.owl-nav-btn.right:before,
.owl-nav-btn.right:after {
    transform: skew(25deg);
    border-radius: 0 10px 0 0;
}

.owl-nav-btn:after {
    transition: 0.3s;
    opacity: 0;
    background-image: linear-gradient(#db502a, #fa4f00);
}

.owl-nav-btn:hover:after {
    opacity: 1;
}

.owl-nav-btn.left {
    left: -50px;
    border-radius: 20px 0 0 0;
    padding-right: 10px;
}

.owl-nav-btn.right {
    right: -50px;
    border-radius: 0 20px 0 0;
    padding-left: 10px;
}

.popular-games .game-btn {
    width: 100%;
    margin: 0;
}

.popular-tag {
    width: 80px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
    background: #52c234;
    background: -webkit-linear-gradient(to right, #3e8f28, #52c234);
    background: linear-gradient(to right, #3e8f28, #52c234);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 1.0);
    color: #fff;
    font-size: 12px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
    border-radius: 0 0 20px 0;
    padding: 0 0 2px;
    z-index: 2;
    font-weight: bold;
}


/* Event Banners */

.event-panel {
    width: calc(100% + 10px);
    margin: 0 -5px;
}

.event-banner {
    width: calc(33.33% - 10px);
    margin: 0 5px 10px;
}

.event-banner .img-panel:after {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 0.3s;
    border: solid 2px var(--yellow);
    z-index: 1;
    opacity: 0;
}

.event-banner:hover .img-panel:after {
    opacity: 1;
}

.event-banner .a-footer {
    width: 100%;
    height: 40px;
    padding: 0 5px 0 10px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 2px 0 0;
    border-radius: 5px;
}

.event-banner .a-footer .title {
    color: #ffffff;
    font-family: 'SBAggroB';
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1.0);
    display: inline-block;
    max-width: calc(100% - 100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.event-banner .view-btn {
    width: 90px;
    height: 28px;
    font-size: 13px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.telegram-link:hover {
    color: #ffffff !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75), 0 0 10px rgba(255, 255, 255, 0.5);
}

.ads-banner {
    width: 100%;
    max-width: 1730px;
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 71px;
    margin: 0 auto;
    z-index: 9;
    padding: 0 10px;
}

.ads-banner .ads-wrap {
    width: 120px;
    margin-left: auto;
    position: relative;
}

.affix .ads-banner .ads-panel {
    max-width: 120px;
    position: fixed;
    top: 70px;
    transition: 0.3s;
}

.ads-panel a {
    width: 100%;
    display: inline-block;
    margin: 0 0 2px;
    pointer-events: auto;
}

.ads-panel a:before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    border: solid 1px #ff790e;
    transition: 0.3s;
    opacity: 0;
}

.ads-panel a:hover:before {
    opacity: 1;
}

.sidebar-left .ads-panel {
    width: calc(100% + 6px);
    margin: 10px -3px 0;
}

.sidebar-left .ads-panel a {
    width: calc(50% - 6px);
    margin: 3px;
}

@media (max-width: 1450px) {

}

.wallet {
    position: fixed;
    left: 58px;
    top: 40%;
    z-index: 99999;
}

.wallet button {
    background: none;
    border: 0px;
}

@media (max-width:1080px ){
    .logo{
        top: px;
    }
    .gnb{
        padding-left: 20px;
        background: url(/public/image/menubg.png) no-repeat;
        background-size: contain;
        height: 55px;
        width: 100%;
        max-width: 340px;
        position: absolute;
        margin-left: -172px;

    }
    .gnb li {
        float: left;
        color: #555
    }

    .gnb li a {
        font-family: TT-Trailers;
        font-size: 20px;
        font-weight: 800;
        line-height: 55px;
        text-align: center;
        padding: 10px 16px;
        color: #00142D;
    }

    .gnb li a:hover {
        font-family: TT-Trailers;
        font-size: 20px;
        line-height: 55px;
        padding: 10px 16px;
        color: #477DFD;
    }

    .gnb .achive {
        font-family: TT-Trailers;
        font-size: 20px;
        line-height: 55px;
        padding: 10px 16px;
        color: #477DFD;
    }
    .login button, .logout button{
        width: 55px;
        height: 55px;
        background-size: cover !important;
    }
    .logout button.mypage{
        width: 55px;
        height: 56px;
        background-size: cover !important;
    }
}

@media (max-width: 1150px) {


    body {
        padding-right: 0 !important;
        text-align: center;
        background-position: top center;
        background-repeat: repeat-x;
        color: #fff;
        font-size: 14px;
        font-family: 'TT-Trailers';
        transition: 0.3s;
        background: url(/public/image/back_bg.png) right;
        min-width: 0px;
        width: 100%;
    }


    .logo {
        /*top: 42px;*/
        left: 30px
    }
    .logo img {
        width: 100%;
        max-width: 130px;
        object-fit: contain;
    }
    .video_box {
        height: 100%;

    }
    #section00{
        background-color: #000;
    }
    #prologue{
        height: 100%;
    }
    #shop .notice_area{
        width: 100%;
        max-width: 710px;
        height: 440px;
    }
    .image img{
        width: 100%;
    }
    #store_view .box{
        text-align: center;
        width: 100%;
        margin: 0 auto;
        max-width: 500px;
    }
    .login,.logout{
        margin-right: 0px;
    }
    .mg0{
        width: 100%;
        margin: 0 auto;
    }
    .width{
        width: 100%;
    }
    #store_view .big_img_area{
        margin: 0 auto;
    }
    .fontS{
        font-size: 30px !important;
        margin-bottom: 10px;
    }
    .video_box .videoPlayer{
        top: 0 !important;
    }
    .slide-navigation{
        max-width: 200px;
        left: 50%;
        transform: translateX(-50%);
        top: 90%;
    }
    .prev-slide,.next-slide{
        position: static;
    }







    .balloon {
        position: absolute;
        z-index: 1;
        animation: moveUpDown 5s infinite;
        margin-left: 0px;
        margin-top: 0px;
        width: 480px;
        left: 20%;
        top: 20%;
        z-index: -1;
    }

    @keyframes moveUpDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0);
        }
    }

    .balloon2 {
        position: absolute;
        right: 20px;
        top: 20%;
        z-index: -2;
        animation: moveUpDown 5s infinite;
        width: 180px !important
    }

    .bpart {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        /*margin-left: -240px*/
    }

    .bpart button {
        background: none;
        border: 0px
    }

    .bpart img {
        padding: 10px;
        transition: 0.3s;
        cursor: pointer
    }

    /*.bpart img:hover {*/
    /*    padding: 10px;*/
    /*    margin-top: -20px;*/
    /*    transition: 0.3s;*/
    /*}*/

    .wallet {
        position: absolute;
        left: 5px;
        top: 15%
    }

    .mgnb {
        display: block
    }

    .mgnb .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: 0px
    }

    .m_menu {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: 0px
    }

    .mgnb li a {
        font-family: TT-Trailers;
        font-size: 26px;
        font-weight: 800;
        line-height: 70px;
        text-align: center;
        padding: 10px 25px;
        color: #00142D;
    }

    .mgnb li a:hover {
        font-family: TT-Trailers;
        font-size: 26px;
        font-weight: 800;
        line-height: 70px;
        text-align: center;
        padding: 10px 25px;
        color: #477DFD;
    }

    .mgnb .achive {
        font-family: TT-Trailers;
        font-size: 26px;
        font-weight: 800;
        line-height: 70px;
        text-align: center;
        padding: 10px 25px;
        color: #477DFD;
    }

    .login_btn {
        position: absolute;
        top: 80%;
        width: 100%;
        left: 0;
        text-align: center
    }

    .login_btn li {
        width: 33.3%;
        text-align: center;
        float: left;
        border: 0px
    }

    .login_btn button {
        border: 0px;
        background: none
    }

    .m_login {
        position: fixed;
        top: 0;
        right: -1400px; /* Start off-screen */
        width: 100%; /* Adjust width as needed */
        height: 100%;
        background-color: white; /* Background color */
        transition: right 0.3s ease; /* Animation effect */
        z-index: 1000; /* Ensure it appears above other content */
    }

    .mo_menu {
        padding-top: 20px
    }

    .m_login.active {
        right: 0; /* Slide into view */
    }

}

@media (max-width: 667px) {


    body {
        padding-right: 0 !important;
        text-align: center;
        background-position: top center;
        background-repeat: repeat-x;
        color: #fff;
        font-size: 14px;
        font-family: 'TT-Trailers';
        transition: 0.3s;
        background: url(/public/image/mback_bg.png);
        background-size: 120%;
        min-width: 0px;
        width: 100%;
    }

    .prev-slide img {
        height: 60px;
        margin-bottom: 65px;
    }

    .next-slide img {
        height: 60px;
        margin-bottom: 65px;
    }

    header {
        width: 100%;
        max-width: 330px;
        position: fixed;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        z-index: 99999;
        left: 50%;
        transform: translateX(-50%);
    }

    .logo {
        top: 25px;
        left: 0 !important;
    }

    .logo img {
        width: 130px
    }
    .store-view{
        /*overflow-x: hidden !important;*/
    }

    .cristal video {
        width: 50%;
        height: 170px;
        object-fit: cover;
        border: 8px solid #fff;
        border-radius: 30px;
        margin-left: -30%;
    }

    .gnb {
        background: url(/public/image/menubg.png) no-repeat;
        background-size: contain;
        width: 100%;
        height: 55px;
        position: absolute;
        top: 58px;
        max-width: 321px;
        padding-left: 0px !important;
        margin-top: 21px;
        z-index: 1;
    }

    .gnb li {
        float: left;
        color: #555
    }

    .gnb li a {
        font-family: TT-Trailers;
        font-size: 20px;
        font-weight: 100;
        line-height: 53px;
        text-align: center;
        padding: 10px 16px;
        color: #00142D;
    }

    .gnb li a:hover {
        font-family: TT-Trailers;
        font-size: 20px;
        line-height: 55px;
        padding: 10px 16px;
        color: #477DFD;
    }

    .gnb .achive {
        font-family: TT-Trailers;
        font-size: 20px;
        line-height: 55px;
        padding: 10px 16px;
        color: #477DFD;
    }

    /*.login {top: 15px; width: 35px;}*/


    .mgnb .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: 0px
    }

    .m_menu {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: 0px
    }

    .bpart button:not(:first-of-type) {
        margin-left: 0;
        padding: 0;
    }

    .balloon {
        position: absolute;
        z-index: 1;
        animation: moveUpDown 5s infinite;
        margin-left: 0px;
        margin-top: 0px;
        width: 280px;
        left: 0%;
        top: 30%;
        z-index: -1;
    }
    #shop,#land{
        background-image: url("/public/image/bg.png");
    }
    .login button,.logout button,.logout button.mypage{
        border:0px;
        background-size: cover !important;
        background-repeat: no-repeat;
    }
    .logout button.mypage{
        width: 44px;
        height: 45px;
    }
    .login button,.logout button{
        width: 45px;
        height: 44px;
    }

    @keyframes moveUpDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0);
        }
    }

    .balloon2 {
        position: absolute;
        right: 20px;
        top: 20%;
        z-index: -2;
        animation: moveUpDown 5s infinite;
        width: 100px !important
    }

    .bpart {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 0%
    }

    .bpart button {
        background: none;
        border: 0px
    }

    .bpart img {
        padding: 10px;
        transition: 0.3s;
        cursor: pointer;
    }

    /*.bpart img:hover {*/
    /*    padding: 20px;*/
    /*    margin-top: -20px;*/
    /*    transition: 0.3s;*/
    /*}*/

    .mt100 {
        margin-top: 50px
    }

    .modal-footer p {
        font-size: 18px;
        word-break: keep-all;
    }

    .w50 {
        width: 100%;
        position: relative
    }

    .big_img {
        width: 340px !important;
        overflow: hidden;
        height: auto !important;
    }

    .small_img {
        width: 90%;
        position: absolute;
        max-width: 480px;
        top: -10px;
        left: 10px
    }

    .small_img2 {
        width: 90%;
        position: absolute;
        top: 10px;
        left: 10px
    }

    .small_img3 img:first-of-type {
        width: 90%;
        max-width: 75px;
        position: absolute;
        top: -20px;
        left: 100px;
    }

    .small_img3 img:last-of-type {
        width: 90%;
        max-width: 75px;
        position: absolute;
        top: -13px;
        right: 100px;
    }

    #store_view {
        position: relative !important;
        width: 100%;
        overflow: hidden;
        /*max-height: 980px;*/
        bottom: 3%;
        padding-top: 10px;
        width: 100%;
        background: url('/public/image/store_view.png') #fff !important;
    }

    #store_view .box {
        position: relative;
        margin-right: 0px;
        padding: 0px !important;
    }

    #store_view .swiper {
        width: 100%;
        height: 110%;
        padding-bottom: 0px !important; /* 80 -> 0 */
        max-height: 395px;
    }

    #store_view .box h1 {
        font-size: 25px !important;
        font-family: 'TT-Trailers';
        -webkit-text-stroke: 1px #00142D;
        color: #fff
    }

    #store_view .box h5 {
        font-size: 20px !important;
        font-family: 'Kvant-Menco-Black';
        color: #477DFD;
        padding-bottom: 0px
    }

    #store_view .box p {
        font-size: 10px !important;
        font-family: 'Kvant-Menco-Black';
        color: #00142D;
        line-height: 1.2em
    }

    #store_view .price_box {
        padding-top: 30px;
        clear: both
    }

    .merch-section .price {
        color: #021E41;
        font-size: 36px;
        clear: both;
        font-family: 'Kvant-Menco-Black';
    }

    .packNum {
        display: flex;
        font-size: 42px;
        margin-top: 10px
    }

    .exchain_btn {
        margin-bottom: 30px
    }

    #land {
        width: 100%;
        /*min-height: 740px;*/
        height: auto;
        background-size: 100% 100%; /*background-attachment: fixed;*/
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        position: relative
    }

    #prologue {
        width: 100%;
        /*min-height: 740px;*/
        height: auto;
        background-size: 100% 100%; /*background-attachment: fixed;*/
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        position: relative
    }
    .login,.logout{
        top: 20px !important;
        right: 0 !important;

    }
    .merch-section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
        color: #333;
        font-family: 'TT-Trailers';
        overflow: hidden;
    }
    .formPopup .pad{
        padding: 0 10px 50px;
        margin-top: 150px;
    }
    .formPopup form{
        width: 90% !important;
    }
    .notice_merch {
        padding: 40px 0px 0px !important;
    }
    .formPopup form input, form textarea{
        box-sizing: border-box !important;
    }
    .formPopup select,.formPopup form input, form textarea{
        max-width: 280px !important;
    }
    .formPopup select{}
    margin-right: 0;
    .select-div{
        max-width: 280px; margin: 0 auto;
    }
    .checkbox-list label{
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
    }
    .p30{
        padding: 0;
    }



    #section03 {
        width: 100%;
        /*min-height: 740px;*/
        height: auto;
        background: url(/public/image/store_bg3.png) #fff;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        background-size: cover;
        position: relative;
    }


    #section03 .swiper-slide img {
        border: 2px solid #00142D;
        border-radius: 30px
    }

    #project {
        /*padding-left: 0 !important;*/
        position: relative;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
        width: 100%;
        /*min-height: 980px;*/
        height: 100% !important;
    }

    #store .slide_list > li.swiper-slide-active .h1_text {
        width: 100%;
        left: 30%;
    }

    .modal-dialog {
        max-width: 970px;
        max-height: 828px;
        margin-top: 0%;
        padding: 0 !important;
    }

    .btn btn_prev
    #project {
        padding: 50px 10px 0;
    }

    .puzy_icon {
        overflow: hidden;
        margin-top: 20px;
    }

    #store {
        position: absolute;
        bottom: 0px;
        width: 100%;
        overflow: hidden;
        max-height: 100%;
        padding-top: 227px;
    }

    #store .slider .btn {
        display: inline-block;
        width: 64px;
        height: 64px;
        margin: 0px 50px;
        background: url(/public/image/arrow.png) center center no-repeat;
        background-size: cover;
        text-indent: -999em;
        cursor: pointer;
        display: none;
    }

    #store_view {
        position: relative !important;
        top: 78px;
        width: 100%;
        overflow: hidden;
        bottom: 3%;
        padding-top: 10px;
        width: 100%;
        background: url('/public/image/store_view.png') #fff !important;
    }

    #store_view .merch-section {
        padding: 80px 0px 80px;
    }

    #store_view .box {
        position: relative;
        margin-right: 0px;
        padding: 0px 20px;
        overflow: hidden;
        min-height: 252px;
    }

    #project {
        width: 100%;
        /*min-height: 980px;*/
        height: 100%;
        /*background: url("/public/image/merge.png") center;*/
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        position: relative;

    }
    .project_00{
        padding-left: 0 !important;
    }

    #project .merge-section {
        max-width: 1280px;
        margin: 165px auto 0;
    }

    #section05 {
        width: 100%;
        min-height: 182px;
        background: #E9F7FB;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        position: relative;
        border-top: 2px solid #000;
        overflow: hidden
    }

    .logo_btn {
        float: none;
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .logo_btn img {
        margin-top: 30px
    }
    .maxHeight{
        max-height: 300px !important;
    }
    .copy {

        text-align: left;
        float: left;
        padding: 15px 20px;
        padding-bottom: 0 !important;
        color: #8AABB5;
        font-family: 'Kvant-Menco-Black';
        font-size: 9px;
    }

    #store_view .box h1 {
        font-size: 25px !important;
        font-family: 'TT-Trailers';
        -webkit-text-stroke: 1px #00142D;
        color: #fff;
        margin-bottom: 0px;
    }

    #detailPage img {
        width: 100%
    }

    #store_view .swiper-pagination {
        position: absolute;
        text-align: center;
        transition: .3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
        bottom: 0px !important;
    }
    #project .btn_list button{
        width: 100%;
    }
    #project .btn_list button img{
        margin-left: -10px;
        max-width: 265px;

    }
    .right_btn {
        display: none
    }
}

#store .slider .btn.btn_prev:focus,
#store .slider .btn.btn_next:focus,
#store .slider .btn.btn_prev:focus-visible,
#store .slider .btn.btn_next:focus-visible {
    outline: none;
    border: none;
    background-color: none !important;
}

#store .swiper-slide:not(.swiper-slide-active) {
    opacity: 0;
}


.right_btn {
    display: none
}

#store .slider .btn.btn_prev:focus,
#store .slider .btn.btn_next:focus,
#store .slider .btn.btn_prev:focus-visible,
#store .slider .btn.btn_next:focus-visible {
    outline: none;
    border: none;
    background-color: none !important;
}

#store .swiper-slide:not(.swiper-slide-active) {
    opacity: 0;
}

.store-view .figure {
    padding: 30px 0px
}

.store-view .figure img {
    width: 100% !important
}

.video_box {
    width: 100%;
    height: 100%;
    position: relative;
    /*max-height: 740px;*/
    padding-top: 56.25%;
}

.video_box iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50% , -490px); /* 강제로 위치 조정 (필요시) */
}

.video_box .videoPlayer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -550px;
    left: 0;
}

.button_box3 button {
    border: 0px !important;
    background: none
}

.detail_area {
    clear: both;
    padding: 30px 0px;
}

.container {
    max-width: 1280px;
    padding: 0;
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;
    margin: 0 auto;
}

.modal-body {
    max-width: 100%;
    padding: 0;
    width: 100%;
    min-width: 830px;
    min-height: auto;
}

.p_area {
    height: 777px;
    position: relative;
}

/*777 -> 580*/
.cristal img {
    border: 8px solid #fff;
    border-radius: 30px;
    height: 440px;
    object-fit: cover;
    width: 100%;
}

.cha_ico {
    bottom: 223px;
    text-align: -webkit-center;
}

.modal-body .bg {
    border-radius: 30px 30px 0 0;
    bottom: 0px;
    height: auto;
    height: 601px;
    max-width: 982px;
    position: absolute;
    width: 100%;
    z-index: -1;
    min-width: 824px;
}

/*601 -> 450*/
.modal-footer {
    border-radius: 0 0 30px 30px;
    border-top: 3px solid #00142d;
    height: 266px;
    justify-content: Center;
    max-width: 100%;
    width: 100%;
    display: none
}

/* none */
.p_area h1 {
    -webkit-text-stroke: 1px #00142d;
    font-family: TT-Trailers;
    display: none;
    font-size: 80px;
    margin-top: 8px;
    padding-top: 27px;
    text-shadow: -4px 3px 1px #00142d;
}

.section4-Modal {
    position: absolute;
    top: 25%;
    margin-left: -210px;
    left: 50%;
    z-index: 9999;
}

.section4-Modal .modal-body {
    max-width: 100%;
    min-height: auto;
    min-width: 0px;
    padding: 0;
    width: 100%;
}

.section4-Modal .modal-close-btn2 {
    background: none;
    border: none;
    border-radius: 0;
    height: 64px;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 64px;
}

.section4-Modal .wallbody li {
    background: #f1f1f4 !important;
    border-radius: 10px;
    color: #00142d;
    display: flex;
    font-family: Kvant-Menco-Black;
    font-size: 16px;
    padding: 12px 16px;
    width: 267px;
    justify-content: center;
}

.X-Social-eventModal{
    position: absolute;
    top: 10%;
    left: 50%;
    z-index: 9999;
    margin-top: 100px;
}


.eventModal .modal-body {
    max-width: 100%;
    min-height: auto;
    min-width: 0px;
    padding: 0;
    width: 100%;
}

.eventModal .modal-close-btn2 {
    background: none;
    border: none;
    border-radius: 0;
    height: 64px;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 64px;
}

.eventModal .wallbody li {
    background: #fff !important;
    border-radius: 10px;
    color: #00142d;
    display: flex;
    font-family: Kvant-Menco-Black;
    font-size: 16px;
    padding: 12px 16px;
    width: 267px;
    justify-content: center;
}
.back-button:hover{
    transform: scale(1.05);
}
.back-button{
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color .3s, transform .2s;
    background-color: grey;
    margin-top: 10px;
}
.disp img{
    width: 100%;
    max-width: 1000px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.disp{
    position: relative;
    height: 100%;
}
@media (max-width: 667px) {
    .disp img{
        max-width: 340px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    .modal-body .bg{
        top: 115px;
    }
    .ant-modal-content{
        max-width: 310px !important;
    }
    .ant-modal-header{
        max-width: 240px !important;
        margin: 0 auto;
    }
    .login_ment{
        text-align: center;
    }


    .login-modal-title{
        margin-bottom: 0 !important;
    }
    .logoutClose{
        right: 10px !important;
        top: 0 !important;
    }
    .logoutPage{
        padding: 25px 25px 95px !important;
    }
    .logoutPage img{
        max-width: 195px !important;
    }
    .outBtn button{
        padding: 12px 20px !important;
        color: #00142D;
    }
    .outText{
        text-align: center;
    }
    .outText,.w_address{
        font-size: 16px;
    }
    .w_address span,.wrap-btn{
        max-width: 240px !important;
    }
    article.bpart button:hover:after {top: 13px !important;left:13px}
    .merges-btn {margin-left: 0px !important;}

    .mypageModal {padding:150px 10px}

    ,.clean-button {
        background-color: #007bff;
        border: none;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 20px;
        transition: background-color .3s, transform .2s;
    }

    #mypageList th {
        background-color: #477DFD;
        border-top: 1px solid #616161;
        font-size: .7rem;
        line-height: 1rem;
        padding: 0px 10px;
        white-space: nowrap;
    }
    #mypageList td {
        color: #000;
        font-size: 0.8rem;
        height: 40px;
        line-height: 1.5rem;
        white-space: nowrap;
    }

    .land_btn {
        background-color: initial;
        border: none;
        margin-top: 0px;
        position: absolute;
        margin: 20px 80px 10px -80px;
        bottom: 0px;
        z-index: 99999;
    }

    .gnb li a {
        color: #00142d;
        font-family: TT-Trailers;
        font-size: 20px;
        font-weight: 100;
        line-height: 53px;
        padding: 10px 16px;
        text-align: center;
    }

    #section00 {
        background-attachment: fixed;
        height: 100%;
        /*max-height: 740px;*/
        min-height: 0;
        position: relative;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
        width: 100%;
    }

    .video_box {
        height: 100%;
        /*max-height: 740px;*/
        padding-top: 0%;
        position: relative;
        width: 100%;
        background: #000;
    }


    .cristal {
        left: 25%;
        margin-left: 0%;
        width: 100%;
    }

    .cristal img {
        border: 8px solid #fff;
        border-radius: 30px;
        height: 150px;
        margin-left: 0;
        object-fit: cover;
        width: 150px !important;
    }

    .modal-body {
        max-width: 100%;
        min-height: auto;
        min-width: 0px;
        padding: 0;
        width: 100%;
    }

    .modal-body .bg {
        border-radius: 30px 30px 0 0;
        bottom: 0;
        height: auto;
        height: 267px;
        max-width: 100%;
        min-width: 0;
        position: absolute;
        width: 98%;
        z-index: -1;
        left: 1%;
    }

    .slide-container {
        display: flex;
        overflow: hidden;
        width: 100%;
        margin-top: 180px;
    }

    .slide-navigation {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 86%;
        transform: translate(-50%,-50%);
        width: 100%;
        max-width: 200px;
        left: 50%;
    }

    .prev-slide {
        left: 0px;
        position: absolute;
    }

    .next-slide {
        position: absolute;
        right: 30px;
    }

    .p_area {
        height: 367px;
        position: relative;
        width: 100%;
        min-width: 330px;
    }

    .modal-content img {
        display: block;
        width: 100%;
    }

    .cha_ico {
        bottom: 120px;
        text-align: -webkit-center;
    }

    /*#shop article {*/
    /*    padding-top: 260px;*/
    /*}*/

    .logo img {

        height: auto;

    }



    #section00, .video_box {
        max-height: 100%;
        position: relative;
        width: 100%;
    }
    #section04{
        height: 100%;
    }


#prologue, #land {
    height: 100%;
    /*min-height: 740px;*/
    position: relative;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    width: 100%;
}
.balloon2 {
  position: absolute;
  right:20px;
  top:20%;
  z-index:-2;
  animation: moveUpDown 5s infinite;
  width:180px !important
  }

.bpart {position:absolute;bottom:30px;left:50%;
  /*margin-left:-240px*/
  transform: translateX(-50%);
}
.bpart button{background:none;border:0px}
/*.bpart img{padding:10px;transition: 0.3s;cursor:pointer}*/
/*.bpart img:hover{padding:10px;margin-top:-20px;transition: 0.3s;}*/
  .wallet{position:absolute;left:5px;top:15%}

.mgnb {display:block}
.mgnb .close_button{position:absolute;top:10px;right:10px;background:none;border:0px}
.m_menu{position:absolute;top:10px;right:10px;background:none;border:0px}
  .mgnb li a{
  font-family: TT-Trailers;
  font-size: 26px;
  font-weight: 800;
  line-height: 70px;
  text-align: center;
  padding:10px 25px;
  color:#00142D;
  }

  .mgnb li a:hover{
  font-family: TT-Trailers;
  font-size: 26px;
  font-weight: 800;
  line-height: 70px;
  text-align: center;
  padding:10px 25px;
  color:#477DFD;
  }
  .mgnb .achive{
  font-family: TT-Trailers;
  font-size: 26px;
  font-weight: 800;
  line-height: 70px;
  text-align: center;
  padding:10px 25px;
  color:#477DFD;
  }
.login_btn {position:absolute;top:80%;width:100%;left:0;text-align:center}
.login_btn li{width:33.3%;text-align:center;float:left;border:0px}
.login_btn button{border:0px;background:none}
.m_login {
  position: fixed;
  top: 0;
  right: -1400px; /* Start off-screen */
  width: 100%; /* Adjust width as needed */
  height: 100%;
  background-color: white; /* Background color */
  transition: right 0.3s ease; /* Animation effect */
  z-index: 1000; /* Ensure it appears above other content */
}
.mo_menu{padding-top:20px}

.m_login.active {
  right: 0; /* Slide into view */
}

    .bpart {
        bottom: 5%;
        left: 0;
        margin-left: 0;
    }

    .slide-navigation {
        left: 4%;
    }

    .slide-container {
        margin-top: 90px !important;
    }



    #shop .swiper {
        height: auto;
        max-height: 100%;
        max-width: 91%;
        padding-bottom: 50px;
        width: 90%;
    }

    #shop .swiper-slide {
        align-items: normal !important;
        background: none;
        border: 0px solid #00142d;
        border-radius: 30px;
        display: flow;
        font-size: 18px;
        justify-content: center;
        padding: 0;
        text-align: center;
        width: 100% !important;
    }

    #shop .notice_area {

        margin: 0 auto;
        padding-bottom: 10px;
        width: 100%;
    }
    #shop article{
        padding-top: 30% !important;
    }


    .wallet {
        left: 5px;
        position: absolute;
        top: 18%;
    }

    .eventModal {
        /*left: 54%;*/
        /*margin-left: 3%;*/
        position: absolute;
        top: 10%;
        z-index: 99999;
        width: 100%;
    }

    .modal-body {
        margin-top: 0%;
        max-width: 100%;
        padding: 0;
        width: 100%;
    }

    .eventModal .wallbody li {
        /*background: #f1f1f4 !important;*/
        border-radius: 10px;
        color: #00142d;
        display: flex;
        font-family: Kvant-Menco-Black;
        font-size: 16px;
        padding: 12px 16px;
        /*width: 90%;*/
        margin-bottom: 10px;
    }

    .eventModal .modal-content li img {
        width: 20px !important;
        margin-right: 10px
    }


=======
.balloon2 {
  position: absolute;
  right:20px;
  top:20%;
  z-index:-2;
  animation: moveUpDown 5s infinite;
  width:100px !important
  }

.bpart {position:absolute;bottom:10%;
  left: 50%; transform: translateX(-50%); margin-left:0%}
.bpart button{background:none;border:0px}
.bpart img{
  padding:0;
  transition: 0.3s;cursor:pointer;max-width:60px;}
/*.bpart img:hover{padding:10px;margin-top:-20px;transition: 0.3s;}*/
.mt100 {margin-top:50px}
.modal-footer p{
font-size: 18px;
word-break:keep-all;
}
.w50{width:100%;position:relative}
  .big_img {
      width: 340px !important;
      overflow: hidden;
      height: auto !important;
  }
.small_img{width:90%;position:absolute;max-width:480px;top:-10px;left:10px}
.small_img2{width:90%;position:absolute;top:10px;left:10px}
.small_img3 img:first-of-type {width: 90%; max-width: 75px; position: absolute; top: -20px; left: 100px;}
.small_img3 img:last-of-type{ width: 90%; max-width: 75px; position: absolute; top: -13px; right: 100px;}
  #store_view {
      position: relative !important;
      width: 100%;
      overflow: hidden;
    bottom: 3%;
    padding-top: 10px;
    width: 100%;
    background: url(/public/image/store_view.png) #fff !important;
}

    .article.bpart button {
        background: none;
        border: none;
        cursor: pointer;
        max-width: 53px;
        position: relative;
        margin: 10px;
    }

    #store_view {
        background: #fff !important;
        bottom: auto;
        overflow: hidden;
        padding-top: 10px;
        position: relative !important;
        top: 0px;
        width: 100%;
        padding-top: 70px;
    }

    #store_view .container {
        max-width: 90%;
        overflow: hidden;
        padding: 0 0 30px;
        position: relative;
        left: 5%;
        margin-left: 0;
    }
    .merch-section figure{
        margin: 0;
    }

    #store_view .swiper-slide {
        padding: 0px;
        text-align: center;
        background-size: contain;
    }
    .merge-section{
        overflow: visible;
    }
    .merge-section .container{
        max-width: 300px;
        overflow: visible;
    }
    .merge_code{
        display: block;
        height: auto;
        max-width: 300px;
    }
    .merge_code input{
        margin-bottom: 30px;
    }
    .merge_code .merge-numb{
        padding: 0 10px;
        font-size: 18px;
        -webkit-text-stroke: 1px black;
    }
    .big_img {
        border: 0 solid #000;
        border-radius: 31px;
        height: auto;
        margin: 10px 34px 40px 10px;
        overflow: hidden;
        padding: 0;
        width: 100%;
    }

    .small_img3 {
        display: none
    }

    #store_view .box {
        margin-right: 0;
        min-height: 0px;
        overflow: hidden;
        padding: 0 20px;
        position: relative;
    }

    .merch-section .price {
        clear: both;
        color: #021e41;
        font-family: Kvant-Menco-Black;
        font-size: 19px;
    }

    .packNum {
        display: flex;
        font-size: 24px;
        margin-top: 10px;
    }

    .hoverable img {
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity .3s;
        width: 30px;
    }

    .packNum span:nth-of-type(2), .packNum span:nth-of-type(odd) {
        margin-left: 38px;
        margin-top: -24px;
    }

    #section05 {
        background: #00142d;
        border-top: 2px solid #000;
        min-height: 193px;
        overflow: hidden;
        position: relative;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
        width: 100%;
    }

    .copy {
        color: #8aabb5;
        float: left;
        font-family: Kvant-Menco-Black;
        font-size: 9px;

        text-align: left;
        width: 85%;
    }

    /*.modal-close-btn2 img{max-width:18px;}*/
    .modal-close-btn2 img {
        max-width: 25px;
    }

    .loginPage {
        padding: 55px 55px 195px;
    }

    .mergeModal {
        padding: 0 25px 35px 20px;
    }
    .merge_ment h5{
        margin-bottom: 30px;
    }
    .imgCheck {
        margin-left: 65px !important;
        max-width: 70px;
    }

    .section4-Modal {
        /*margin-left: -140px;*/
    }

    .login-form {
        position: absolute;
        top: 230px;
        left: 50%;
        transform: translateX(-50%);
    }

    /* 셀렉트 박스 컨테이너 */
    .select-box {
        position: relative;
        display: inline-block;
        width: 340px !important; /* 너비 */
        padding: 10px 18px;
        border: 1px solid #F1F1F4;
        background-color: #F1F1F4;
        border-radius: 8px;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

.btn_check {
    width: 200px !important;
}

.iceCream {
    max-width: 200px;
}
.video_box .videoPlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -550px;
  left: 0;
}
.button_box3 button{border:0px !important;background:none}
.detail_area{clear: both;padding: 30px 0px;}
.container {
max-width: 1280px;
padding: 0;
position: relative;
overflow: hidden;
padding-bottom: 30px;
}

.modal-body {max-width: 100%;padding: 0;width: 100%;min-width: 830px; min-height: auto;}
.p_area {height: 777px;position: relative;} /*777 -> 580*/
.cristal img {border: 8px solid #fff;border-radius: 30px;height: 440px;object-fit: cover;width: 100%;}
.cha_ico {bottom: 223px;text-align: -webkit-center;}
.modal-body .bg {border-radius: 30px 30px 0 0;bottom: 0px;height: auto;height: 601px;max-width: 982px;position: absolute;width: 100%;z-index: -1;min-width: 824px;} /*601 -> 450*/
.modal-footer {border-radius: 0 0 30px 30px;border-top: 3px solid #00142d;height: 266px;justify-content: Center;max-width: 100%;width: 100%; display: none } /* none */
.p_area h1 {-webkit-text-stroke: 1px #00142d;font-family: TT-Trailers;display: none; font-size: 80px;margin-top: 8px;padding-top: 27px;text-shadow: -4px 3px 1px #00142d;}

.section4-Modal {position: absolute;left: 50%;z-index: 9999;}
.section4-Modal .modal-body {max-width: 100%;min-height: auto;min-width: 0px;  padding: 0;width: 100%;}
.section4-Modal .modal-close-btn2 { background: none;border: none;border-radius: 0;height: 64px;position: absolute;right: 20px;top: 10px;width: 64px;}
.section4-Modal .wallbody li {
    /*background: #f1f1f4 !important;*/
    border-radius: 10px;
    color: #00142d;
    display: flex;
    font-family: Kvant-Menco-Black;
    font-size: 16px;
    padding: 12px 16px;
    width: 267px;
    justify-content: center;
}

.eventModal {position: absolute;top: 10%;
    left: 50%; transform: translateX(-50%);
    /*margin-left: -240px;left: 50%;*/
    z-index: 9999;}
.eventModal .modal-body {max-width: 100%;min-height: auto;min-width: 0px;  padding: 0;width: 100%;}
.eventModal .modal-close-btn2 { background: none;border: none;border-radius: 0;height: 64px;position: absolute;right: 20px;top: 10px;width: 64px;}
.eventModal .wallbody li {
    /*background: #f1f1f4 !important;*/
    border-radius: 10px;
    color: #00142d;
    display: flex;
    font-family: Kvant-Menco-Black;
    font-size: 16px;
    padding: 12px 16px;
    width: 267px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

/*.eventModal .wallbody div button{*/
/*    background-color: #3A78FF;*/
/*    height: 50px;*/
/*    width: 150px;*/
/*    border-radius: 15px;*/
/*    color:white;*/
/*}*/
.eventModal .wallbody div p{
    color:black;
}

.land_btn {
    background-color: transparent;
    border: none;
    margin-top: 250px;
}
.posi-r {
    position: relative;
}
@media (max-width: 667px) {
    .project_bg{
        background-image: none;
        background-color: #B1E0FA;
    }
    .merge-panel{
        gap: 10px;
    }
    .merge-panel a{
        width: calc((100% / 2) - 10px);
    }
    .merge-btn .g-panel{
        padding: 10px;
    }
    .merge-text p{
        line-height: 32px;
        font-size: 24px;
    }
    .merge_code input{
        padding-left: 100px;
    }
    .g-panel input{
        width: 20px;
        height: 20px;
        top: 20px;
        left: 20px;
    }
    #store_view .box.height{
        height: 100%;  /* 190 -> 100% */
        /*overflow-y: scroll;*/
    }
    #store_view .exchain_btn{
        margin-top: 30px;
    }
    .section4-Modal{
        left: 63%;
        top: 30%;
    }
  .gnb li a {
      color: #00142d;
      font-family: TT-Trailers;
      font-size: 20px;
      font-weight: 100;
      line-height: 53px;
      padding: 10px 16px;
      text-align: center;
  }
    
   #section00 {background-attachment: fixed;height: 100%;
       /*max-height: 740px;*/
       min-height: 0;position: relative;transition: all .5s ease-in-out;-webkit-transition: all .5s ease-in-out;width: 100%;}
  .video_box {height: 100%;
      /*max-height: 740px;*/
      padding-top: 0%;position: relative;width: 100%;background: #000;}
    .checkbox-list span{
        max-width: 180px;
    }
  
   .cristal {left: 25%;margin-left: 0%;width: 100%;}
   .cristal img {border: 8px solid #fff;border-radius: 30px;height: 150px;margin-left: 0;object-fit: cover;width: 150px !important;}
  .modal-body {max-width: 100%;min-height: auto;min-width: 0px;padding: 0;width: 100%;}
  .modal-body .bg {border-radius: 30px 30px 0 0;bottom: 0;height: auto;height: 267px;max-width: 100%;min-width: 0;position: absolute;width: 98%;z-index: -1;left: 1%;}
  .slide-container {display: flex;overflow: hidden;width: 100%;margin-top: 180px;}
  .slide-navigation {display: flex;justify-content: space-between;position: absolute;transform: translate(-50%,-50%);width: 100%;  left:54%;}
  .prev-slide {left: 0px;position: absolute;}
  .next-slide {position: absolute;right: 30px;}
  .p_area {height: 382px;position: relative;width: 100%;min-width: 330px;}
  .modal-content img {display: block;width: 100%;}
  .cha_ico {bottom: 135px;text-align: -webkit-center;   }

   #section02 article {padding-top: 260px;}


  .wallet {left: 5px;position: absolute;top: 18%;}

   .modal-body {margin-top: 0%;max-width: 100%;padding: 0;width: 100%;}
  .eventModal .wallbody li {
    /*background: #f1f1f4 !important;*/
    border-radius: 10px;color: #00142d;display: flex;font-family: Kvant-Menco-Black;font-size: 16px;padding: 12px 16px;
    /*width: 90%;*/
    margin-bottom: 10px;}
  .eventModal    .modal-content li img {width:20px !important;margin-right:10px}

  
  .article.bpart button {background: none;border: none;cursor: pointer;max-width: 53px;position: relative;margin: 10px;}
   #section05 {background: #00142d;border-top: 2px solid #000;min-height: 193px;overflow: hidden;position: relative;transition: all .5s ease-in-out;-webkit-transition: all .5s ease-in-out;width: 100%;}
  .copy {color: #8aabb5;float: left;font-family: Kvant-Menco-Black;font-size: 9px;text-align: left;}
  .modal-close-btn2 img{max-width:18px;}
  .loginPage{padding: 55px 55px 195px;}
  .imgCheck{margin-left: 65px !important; max-width: 70px;}
  .login-form{
    position: absolute;
    top: 230px;
    left: 50%;
    transform: translateX(-50%);
  }
    .ant-modal-content{
        margin-top: 65px;
    }

}

/* 특정 img에서 transition 제거 */
.no-transition {
    transition: none !important;
}


.resize-container .image img {
    background-size: cover; /* 배경 이미지가 컨테이너 크기에 맞게 조정됨 */
    background-position: center; /* 이미지가 중앙에 위치하도록 설정 */
    background-repeat: no-repeat; /* 이미지가 반복되지 않음 */
    width: 100%;
    /*height: 100vh; !* 뷰포트 전체 높이 사용 *!*/
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* 텍스트 색상을 대비되게 설정 */
    text-align: center;
}

.select-div {
    padding-bottom: 17px;
}

/* 셀렉트 박스 컨테이너 */
.select-box {
    position: relative;
    display: inline-block;
    width: 100%; /* 너비 */
    padding: 10px 18px;
    border: 1px solid #F1F1F4;
    background-color: #F1F1F4;
    border-radius: 8px;
    margin-right: 5px;
    margin-bottom: 10px;
}

/* 셀렉트 박스 자체 */
.select-box select {
    width: 100%;
    height: 60px; /* 높이를 명확히 지정 */
    padding: 0 16px; /* 좌우 패딩 */
    font-size: 18px; /* 폰트 크기 확대 */
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px; /* 둥근 모서리 */
    appearance: none; /* 기본 화살표 제거 */
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    box-sizing: border-box; /* 패딩 포함 크기 계산 */
}

/* 화살표 아이콘 */
.select-box::after {
    content: '▼'; /* 화살표 아이콘 */
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 14px;
    color: #999;
}

/* hover 효과 */
.select-box select:hover {
    border-color: #888;
}

/* focus 효과 */
.select-box select:focus {
    outline: none;
    border-color: #555;
}

/* 옵션 스타일 */
.select-box select option {
    padding: 14px; /* 옵션 높이 */
    font-size: 16px;
}

.height{
    height: 230px;
    overflow-y: scroll;
}
.mgTop{
    margin-top: 25px;
}
::-webkit-scrollbar {
    width: 10px; /* 세로 스크롤바의 너비 */
    height: 10px; /* 가로 스크롤바의 높이 */
}

/* 스크롤바의 트랙 (스크롤이 움직이는 부분) */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* 트랙의 배경색 */
    border-radius: 10px;
}

/* 스크롤바의 썸 (스크롤을 잡고 움직이는 부분) */
::-webkit-scrollbar-thumb {
    background: #888; /* 썸의 배경색 */
    border-radius: 10px; /* 둥글게 처리 */
    border: 3px solid #f1f1f1; /* 트랙과 썸 사이의 여백 */
}

.checkbox-list {
    list-style: none !important;
    padding: 0 !important;
    margin: 10px 0;
}

.checkbox-list li {
    margin-bottom: 15px !important;
}

.checkbox-label {
    display: flex !important;
    /*align-items: center !important;*/
    font-size: 16px !important;
    cursor: pointer !important;
}

.checkbox-input {
    appearance: none !important;
    -webkit-appearance: none !important;
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #007BFF !important;
    border-radius: 4px !important;
    margin-right: 10px !important;
    cursor: pointer !important;
    transition: background-color 0.3s, border-color 0.3s !important;
    padding : 0px !important;
}

.checkbox-input:checked {
    background-color: #007BFF !important;
    border-color: #007BFF !important;
    position: relative !important;
}

.checkbox-input:checked::after {
    content: "" !important;
    display: block !important;
    width: 8px !important;
    height: 8px !important;
    background: white !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 2px !important;
}

.viewButton {
    margin-left: 10px!important;
    padding: 5px 10px!important;
    background-color: #007BFF!important;
    color: white!important;
    border: none!important;
    border-radius: 4px!important;
    cursor: pointer!important;
    transition: background-color 0.3s!important;
    height: 10% !important;
    width: 20% !important;
    font-size: 12px !important;
    font-weight: lighter !important;
    line-height: 10px !important;
}

.buttonLeft{
    margin-left: auto !important;
}

.viewButton:hover {
    background-color: #0056b3!important;
}

.m-close-btn {
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.m-close-btn img {
    width: 40px;
    height: 40px;
    display: block;
}

/* aptos 지갑 */
.wallet-button {
    /*padding: 10px 20px;*/
    height: auto;
    border-radius: 6px;
    font-size: 16px;
    background-color: #3f67ff;
    color: white;
    padding: 0 !important;
}
.outText,.w_address{
    max-width: 288px;

}
.w_address{
    background-color: #F1F1F4;
    border-radius: 10px;
    padding: 10px 0;
}
.w_address span{
    display: block;
    max-width: 260px;
    width: 100%;
    margin: 0 auto;
    word-wrap: break-word;
}
.outBtn{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.outBtn button{
    font-family: 'kvant-Menco-black';
    font-size: 20px;
    background-color: #F1F1F4;
    border: 2px solid #00142d;
    border-radius: 15px;
    padding: 20px 38px;
    box-shadow: 5px 6px 0 rgba(0, 0, 0, 1.0);

}

.outBtn button:first-child{
    margin-right: 10px;
}
.outBtn button:hover{
    background-color: #477DFD;
    transition: all 0.3s ease;
    color: #fff;
}
.logoutPage{
    padding: 80px 80px 160px;
}
.logoutPage img{
    width: 100%;
    max-width: 240px;
}
.viewButton{
    -webkit-text-stroke: 0px;
    font-family: 'kvant-Menco-black';
}
.form-container{
    font-family: 'noto-sans-bold';
}
.scrollable{
    overflow-y: scroll !important;
}
.formPopup input{
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.formPopup input:not(.checkbox-input){
    margin: 0 auto;
}

#webpack-dev-server-client-overlay {
    display: none !important;
}
#land{
    background-size: cover;
    background-position: center center;

}
.merge_ment{
    text-align: center;
    width: 100%;
    max-width: 345px;
}
.more_container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}
.line{
    margin: 10px 0;
}
.price_container{
    text-align: left;
    border-top: 2px solid #F1F1F4;
    border-bottom: 2px solid #F1F1F4;
    padding: 20px;
    background-color: #F1F1F4;
    border-radius: 5px;
    position: relative;
}
.price_container .price_label{
    margin-bottom: 20px;
    display: inline-block;
    width: 40%;
    font-family: 'noto-sans-bold';
    color: #00142d;
    font-size: 14px;
    font-weight: 400;
}

.price_container #price{
    display: inline-block;
    width: 60%;
    text-align: right;
    border: none;
    padding: 0;
}
.price_container .totalPrice{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0 !important;
}
.wrap-btn{
    max-width: 340px;
    margin: 0 auto;
}
.price_container b{
    display: none;
    color: grey;
    font-family: 'noto-sans-bold';
    font-size: 12px;
}

.X-Social-eventModal{
    left: 86%;
}

.X-Social-eventModal a{
    color: #00142D;
}
.X-Social-eventModal .modal-close-btn2{
    top: 0;
    right: 5px;
}
.X-ment{
    text-align: center;
}
.X-img{
    max-width: 30px;
}
.X-Social-eventModal .wallbody li{
    flex-direction: row;
}
/*@media (max-width: 800px){*/
/*    .X-Social,.X-Social-eventModal{*/
/*        display: none;*/
/*    }*/

/*}*/
.xModal{
    padding: 36px 10px;
}
.xModal li{
    width: 180px !important;
}
.ant-modal{
    width: 390px !important;
}
.login_ment{
    text-align: center;
}
@media (max-width: 800px) {
    .gnb {
        background: url(/public/image/menubg.png) no-repeat;
        background-size: contain;
        width: 100%;
        height: 55px;
        position: absolute;
        top: 58px;
        transform: translateX(-50%);
        /* right: 2%; */
        margin: 0 auto;
        padding-left: 0px;
        margin-top: 21px;
        z-index: 1;
    }

    .gnb li {
        float: left;
        color: #555
    }

    .gnb li a {
        font-family: TT-Trailers;
        font-size: 20px;
        font-weight: 100;
        line-height: 53px;
        text-align: center;
        padding: 10px 16px;
        color: #00142D;
    }

    .gnb li a:hover {
        font-family: TT-Trailers;
        font-size: 20px;
        line-height: 55px;
        padding: 10px 16px;
        color: #477DFD;
    }

    .gnb .achive {
        font-family: TT-Trailers;
        font-size: 20px;
        line-height: 55px;
        padding: 10px 16px;
        color: #477DFD;
    }
    /*.login,.logout{*/
    /*    top: 20px !important;*/
    /*    right: 50px !important;*/
    /*}*/
    .logout button.mypage{
        width: 44px;
        height: 45px;
    }
    .login button,.logout button{
        width: 45px;
        height: 44px;
    }

}
.login .wallet-button{
    background-image: url(/public/image/loginBtn.png) !important;
    background-repeat: no-repeat;
    margin-left: 10px;
}

.checkbox-list span{
    font-size:13px;
}
.opacity{
    opacity: 0;
}
@media (max-width: 1280px){
    .video_box{
        padding-top: 0;
    }
    .video_box iframe{
        width: 100%;
        left: 0;
        transform: translate(0,0);
    }
}
.merge-image{
    width: 100%;
    height: 160px;
    border: 2px solid #00142D;
    border-radius: 10px;
    max-width: 196px;
    overflow: hidden;
}
@media (max-width: 350px){
    .disp img{max-width: 320px;}

}

.header-scroll {
    transition: opacity 0.3s ease-out;
}

.opacity-hidden {
    opacity: 0;
}

.product-content > p {
    width: 100%; /* 현재 너비 유지 */
    /*max-width: 400px; !* 기존 컨테이너 너비 *!*/
    /*overflow: hidden; !* 내용이 넘칠 경우 숨김 *!*/
    /*text-overflow: ellipsis; !* 텍스트가 넘치면 말줄임표 추가 (선택사항) *!*/
    word-wrap: break-word; /* 긴 단어를 줄바꿈 */
    overflow-wrap: break-word; /* 긴 단어를 줄바꿈 */
    line-height: 1.5; /* 줄 간격을 넓게 설정 */
}