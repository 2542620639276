/* CSS Variables (기본 색상, 크기, 반응형 속성 등을 정의합니다) */
:root {
    --zindex-modal: 1050;
    --zindex-modal-backdrop: 1040;
    --modal-backdrop-bg: rgba(0, 0, 0, 0.5);
    --modal-content-color: #333;
    --modal-content-bg: #fff;
    --modal-content-border-color: #dee2e6;
    --modal-content-border-width: 1px;
    --modal-header-padding: 1rem;
    --modal-title-line-height: 1.5;
    --modal-inner-padding: 1rem;
    --modal-footer-margin-between: 0.5rem;
    --modal-md: 500px;
    --modal-lg: 800px;
    --modal-xl: 1000px;
  }

  .w-100 {
    width: 100% !important;
  }

  .modal-open {
    overflow: hidden;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal {
     top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  
  .modal.show {
    display: block;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 1.75rem auto;
     transition: transform 0.3s ease-out;
  }
  
  .modal.fade .modal-dialog {
    transform: translate(0, -25%);
  }
  
  .modal.show .modal-dialog {
    transform: translate(0, 0);
  }
  
  .modal.modal-static .modal-dialog {
    transform: scale(1.05);
  }
  
  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 3.5rem);
  }

.apple-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 3vh;*/
    height: 40px;
    flex-direction: column;
    background-color: #f5f5f5; /* 배경 색상 */
    /*padding: 20px;*/
}
#appleid-signin{
    max-height: 40px;
}
