/* aptos 지갑 */
/*.wallet-button {*/
/*  padding: 10px 20px;*/
/*  height: auto;*/
/*  border-radius: 6px;*/
/*  font-size: 16px;*/
/*  background-color: #3f67ff;*/
/*  color: white;*/
/*}*/
.wallet-modal-title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  /*visibility: hidden;*/
}
.aptos-connect-button {
  color: #3f67ff;
  border-color: #3f67ff;
}
.aptos-connect-privacy-policy-wrapper {
  display: none;
  flex-direction: column;
  align-items: center;
}
.aptos-connect-privacy-policy-text {
  font-size: 12px;
  line-height: 20px;
}
.aptos-connect-privacy-policy-link {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  text-underline-offset: 4px;
}
.aptos-connect-privacy-policy-link:hover {
  text-decoration: underline;
}
.aptos-connect-powered-by {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.wallet-menu-wrapper {
  display: none;
  justify-content: space-between;
  font-size: 20px;
  border: solid 1px;
  border-color: rgba(0, 0, 0, 0.1);
  padding-inline: 16px;
  padding-block: 12px;
  border-radius: 8px;
}
.wallet-name-wrapper {
  display: flex;
  align-items: center;
}
.wallet-selector-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}
.wallet-selector-text {
  font-size: 14px;
}
.wallet-connect-button {
  align-self: center;
  background-color: #3f67ff;
  color: white;
  height: auto;
}
.wallet-connect-install {
  align-self: center;
  color: #3f67ff;
  padding-right: 16px;
  font-size: 16px;
  line-height: 32px;
}
.about-aptos-connect-trigger-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}
.about-aptos-connect-trigger {
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-inline: 0;
  padding-block: 12px;
  color: black;
}
.about-aptos-connect-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  margin-bottom: 16px;
}
.about-aptos-connect-header > div {
  margin: 0;
  font-size: 18px;
}
.about-aptos-connect-graphic-wrapper {
  display: flex;
  height: 162px;
  padding-bottom: 12px;
  align-items: flex-end;
  justify-content: center;
}
.about-aptos-connect-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  padding-bottom: 4px;
}
.about-aptos-connect-title {
  font-size: 22px;
}
.about-aptos-connect-description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.about-aptos-connect-description > a {
  color: black;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.about-aptos-connect-footer-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
}
.about-aptos-connect-screen-indicators-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  place-self: center;
}
.about-aptos-connect-screen-indicator {
  background: none;
  border: none;
  padding-inline: 0;
  padding-block: 16px;
  cursor: pointer;
}
/*.ant-modal-header{*/
/*    display: none;*/
/*}*/
.wallet-button:hover {
  background-image: url(/public/image/loginBtn.png) !important;
  background-color: inherit !important; /* 배경색을 상속받게 하여 기본값으로 유지 */
}
.about-aptos-connect-screen-indicator > div {
  height: 2px;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}
.about-aptos-connect-screen-indicator[data-active] > div {
  background-color: black;
}
.ant-collapse{
  display: none;
}
.ant-flex .ant-divider{
  display: none;
}
.ant-modal .ant-modal-content{
  padding: 15px 24px 50px;
  margin: 0 auto;
}
:where(.wallet-selector-modal .ant-modal-body) {
  display: flex;
  flex-direction: column;
  /*gap: 16px;*/
}
:where(.wallet-selector-modal .ant-divider-inner-text) {
  color: rgba(0, 0, 0, 0.5);
}
:where(.wallet-selector-modal .ant-collapse-header) {
  justify-content: center !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
:where(.wallet-selector-modal .ant-collapse-header-text) {
  flex: unset !important;
  margin-inline-end: 0 !important;
}
:where(.wallet-selector-modal .ant-collapse-content-box) {
  padding: 0px !important;
  padding-top: 8px !important;
}

.wallet-text {
  max-width: 100%!important;
  padding: 0!important;
  width: 100%!important;
  min-width: 0px!important;
  min-height: auto!important;
}
.wallet-loginPage{
  padding: 20px 5px 0;
}

.ant-modal-close-x svg {
  display: none; /* 기존 SVG 숨기기 */
}

.ant-modal-close-x {
  background-image: url('/public/image/close.png'); /* 이미지 경로 설정 */
  background-repeat: no-repeat; /* 반복 방지 */
  background-size: contain; /* 이미지 크기를 맞춤 */
  background-position: center; /* 이미지 중앙 정렬 */
  width: 24px; /* 너비 설정 */
  height: 24px; /* 높이 설정 */
  margin: 0 0px -12px -16px;
}

/* wallet 팝업 디자인*/
.ant-modal-content {
  background: url('/public/image/product_boxbg.png') no-repeat !important;
  background-position: center; /* 이미지를 중앙에 배치 */
  background-size: 100% 100% !important;
  width: auto !important;
  z-index: 99999;
}

.ant-flex-align-stretch{
  gap: 12px;
  display: flex;
  align-items: center;

}
.ant-flex-align-stretch button{
  width: 300px;
}

@media  (max-width: 768px) {
  .ant-modal-content {
    background: url('/public/image/product_boxbg.png') no-repeat !important;
    background-position: center; /* 이미지를 중앙에 배치 */
    background-size: 100% 100% !important;
    width: auto !important;
    z-index: 99999;
  }
  /*.ant-modal .ant-modal-content{*/
  /*  padding: 50px 80px;*/
  /*}*/
}