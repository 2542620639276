#mypageList{
    width: 100%; border: none; font-family: 'noto-sans-bold';
}
#mypageList th{
    border-top: 1px solid #00142D; background-color: #477DFD; font-size: 0.9rem; line-height: 1rem;
}
#mypageList th:first-of-type{
    padding-left: 10px; border-left: 1px solid #616161; width: 5%;
}
#mypageList th:nth-of-type(2){width: 13%;}
#mypageList th:nth-of-type(3){width: 10%;}
#mypageList th:nth-of-type(4){width: 5%;}
#mypageList th:nth-of-type(5){width: 10%;}
#mypageList th:nth-of-type(6){width: 10%;}
#mypageList th:nth-of-type(7){width: 10%;}
#mypageList th:nth-of-type(8){width: 17%;}
#mypageList th:last-of-type{
    padding-right: 10px; border-right: 1px solid #616161; width: 20%;
}
#mypageList td{
    color: #000; font-size: 1rem; line-height: 1.5rem; height: 70px;
}
#mypageList tbody tr:nth-child(even){
    background-color: #fff;
}
#mypageList tbody tr:nth-child(odd){
    background-color: #f2f2f2;
}
.hisFont{
    font-size: 75px;
    -webkit-text-stroke: 2px black;
    font-family: TT-Trailers !important;
    margin: 0;
    padding-Top: 5%;
}
.marginTop{
    margin-top: 100px;
}
.clean-button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.clean-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.clean-button:active {
    background-color: #003f7f;
    transform: scale(0.95);
}

.clean-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
@media (max-width: 600px){
    .hisFont{
        font-size: 60px;
    }
}