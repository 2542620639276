.payment{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;

}
.p-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.p-wrap img{
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}
.payment button{
    font-family: 'Kvant-Menco-Black';
    background-color: #477DFD;
    border: 2px solid black;
    border-radius: 20px;
    padding: 18px 32px;
    box-shadow: 8px 6px 0 #000;
    font-size: 30px;
    font-weight: 900;
    color: #fff;
    -webkit-text-stroke: 2px black;
}
.p-wrap p{
    text-align: center;
    font-family: 'TT-Trailers';
}


.p-wrap p:first-of-type{
    color: #fff;
    font-weight: 900;
    font-size: 60px;
    -webkit-text-stroke: 2px black;
    margin-bottom: 15px;


}
.p-wrap p:nth-of-type(2){
    color: gray;
    margin-bottom: 60px;
    font-size: 24px;
}
.fail p:nth-of-type(2){
    max-width: 300px;
}
.btn_container{
    width: 100%;
    max-width: 640px;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.btn_container button:first-of-type{
    background-color: #A9FF99;

}


@media (max-width: 430px) {
    .p-wrap img{
        max-width: 150px;
    }
    .success button{
        font-size: 22px;
        -webkit-text-stroke: 1.3px black;
        padding: 10px 10px;
    }
}

/* form page */
.p-bg{
    background-image: url('/public/image/store2.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.formPopup{
    width: 100%;
    margin: 0 auto;


}
.formPopup form {
    background-color: white;
    margin: 30px auto 0;
    padding: 0px 5.21vw;
    border: 3px solid #00142D;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    border-radius: 20px;
}
.formPopup form:not(p){
    font-family: 'Kvant-Menco-Black';
}
.formPopup form p{
    font-size: 53px;
    font-family: 'TT-Trailers';
    line-height: 68.9px;
    font-weight: 800;
    text-align: center;
    text-shadow: -2px 2px 0 #00142D;
    -webkit-text-stroke: 1px #00142D;
    color: #fff;
}


/* 입력창 및 레이블 스타일 */
.formPopup form label {
    text-align: left;
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
    color: #477DFD;
    font-size: 18px;
    line-height: 19.2px;
}

.formPopup form input, form textarea {
    /*width: 100%;*/
    /*padding: 10px 18px;*/
    /*border: 1px solid #F1F1F4;*/
    /*border-radius: 8px;*/
    /*background-color: #F1F1F4;*/
    /*max-height: 40px;*/
    /*box-sizing: border-box;*/
}
.formPopup form input:not(:last-of-type){
    margin-bottom: 24px;
}
/*.formPopup form input:last-of-type{*/
/*	margin-bottom: 48px;*/

/*}*/

/* 버튼 스타일 */
.formPopup form .formExit{
    position: absolute; top: 27px; right: 27px;
    background: none;
    border: none;
}

.formPopup button:not(.viewButton) {
    display: inline-block;
    padding: 16px 0;
    margin: 0;
    cursor: pointer;
    letter-spacing: 1%;
    font-family: 'kvant-Menco-black';
    font-weight: 900;
    font-size: 26px;
    line-height: 31.2px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    -webkit-text-stroke: 1.8px #00142D;
    color: #fff;
    border: 2px solid #00142D;
    border-radius: 20px;
    position: relative;
    box-shadow: 5px 6px 0 0 #00142D;
}

.formPopup button[type="reset"] {
    background-color: #EDEDF2; margin-right: 10px;

}

.formPopup button[type="submit"] {
    background-color: #477DFD;
}
.list-check-1{
    background-color: #F1F1F4;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
}
.list-check-1 li{
    display: flex;
    width: 33.33%;
}
.list-check-1 li:nth-child(n+4) {
    margin-top: 20px;  /* 4번째 항목부터 margin-top 추가 */
}
.list-check-1 li input{
    max-width: 15px;
    height: 20px;

}

.list-check-1 li label{
    margin: 2px 0 0 5px;
    font-size: 16px;
}
.title-type-3{
    color: #477DFD;
    font-size: 18px;
    text-align: left;
}
.wrap-btn{
    display: flex;
    justify-content: space-around;
}

.force-view {
    display: none !important;
}

.formPopup{max-width:800px;margin:0 auto}
.formPopup input{
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    background: none;
    border-radius: 5px;
    grid-area: field;
    padding: 15px 10px;
    letter-spacing: inherit;
    line-height: inherit;
    text-decoration: inherit;
    text-overflow: ellipsis;
    border:1px solid #dedede;
    width:100%;
    /*margin-bottom:15px*/
}
.formPopup select{
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    background: none;
    border-radius: 5px;
    grid-area: field;
    padding: 12.5px 10px 12.5px;
    letter-spacing: inherit;
    line-height: inherit;
    text-decoration: inherit;
    text-overflow: ellipsis;
    border:1px solid #dedede;
    width:100%;
    color:#757575;
    margin-bottom:15px
}
.formPopup input:focus {
    outline:2px solid #80abff;
}
.formPopup label{display:none}
.select-div label{display:block;position:absolute;padding-left:15px;font-size:8pt;padding-top:5px;font-weight:bold;}
